import React from "react";

interface TitleBarButtonProps {
    icon: string;
    onClick?: () => void;
}

export function TitleBarButton({ icon, onClick }: TitleBarButtonProps) {
    return (
        <button
            className="btn btn-lg btn-outline-secondary border-0 py-1 px-2 mb-0"
            onClick={onClick}
        >
            <i className={`fas ${icon}`} />
        </button>
    );
}
