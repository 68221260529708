import { useCallback, useContext } from "react";

import { Button } from "../../land_ui/button/button";
import { QuickSearch } from "../../land_ui/quick_search/quick_search";
import { Typography } from "../../land_ui/typography/typography";
import { ParcelViewerContext } from "./context";
import { findFeatureOnMap, findParcelOnMap } from "./controls/search";
import { Feature } from "geojson";
import { useMap } from "react-map-gl";
import type { Point, SearchResult } from "./types";

interface MapHeaderProps {
    setActiveParcelID: (parcelID: number) => void;
    setSearchResult: (result: SearchResult) => void;
    setShowFilterPanel: (showFilterPanel: boolean) => void;
}

export function MapHeader({
    setActiveParcelID,
    setSearchResult,
    setShowFilterPanel,
}: MapHeaderProps) {
    const { setMapFilter, county, setCounty } = useContext(ParcelViewerContext);
    const { current: map } = useMap();

    // Locate parcel on map and mark it active
    const highlightParcel = useCallback(
        async (point: Point) => {
            setMapFilter(null);

            const result = await findParcelOnMap(map, point);

            if (result && result.mapFeature) {
                const { identifyLayer, idField, mapFeature } = result;
                const parcelID = parseInt(`${mapFeature.id}`, 10);
                const filter = ["==", idField, parcelID];
                const inverseFilter = ["!", filter];
                setMapFilter({ identifyLayer, filter, inverseFilter });
                setActiveParcelID(parcelID);
            }
        },
        [map, setMapFilter, setActiveParcelID],
    );

    // Locate feature on map and highlight it by applying shadow filter
    const highlightFeature = useCallback(
        async (feature: Feature) => {
            // Reset filters
            setMapFilter(null);

            const result = await findFeatureOnMap(map, feature);

            if (result && result.mapFeature) {
                const { identifyLayer, filter, idField, mapFeature } = result;

                // Filter layer by feature ID
                let newFilter = ["==", idField, mapFeature.id];
                let inverseFilter = ["!", newFilter];

                // Include County/Zip layer filter
                if (filter) {
                    newFilter = ["all", filter, newFilter];
                    inverseFilter = ["all", filter, inverseFilter];
                }

                // Apply map filter for shadow layer
                setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });

                // Mark county as "active" for Parcel # search
                const featureType = feature?.properties?.feature_type;
                if (featureType === "district") {
                    setCounty({
                        // id is the county FIPS code
                        id: mapFeature.properties.code,
                        name: feature.properties.name,
                    });
                }
            }
        },
        [map, setMapFilter, setCounty],
    );

    return (
        <div className="lui-absolute  lui-z-20 lui-top-5 lui-left-6 lui-flex lui-gap-3 lui-items-center">
            <Button variant="secondary" icon="Hamburger" />
            <Button
                variant="primary"
                icon="Filter"
                onClick={() => {
                    setShowFilterPanel(true);
                }}
            >
                Filter Parcels
            </Button>

            <Typography color="white" weight="bold" size="lg">
                or
            </Typography>
            <QuickSearch
                county={county}
                onSearch={(search) => {
                    if (search.point) {
                        highlightParcel(search.point);
                    }

                    if (search.ownerResults) {
                        setSearchResult(search.ownerResults);
                    }

                    if (search.feature) {
                        highlightFeature(search.feature);
                    }
                }}
            />
        </div>
    );
}
