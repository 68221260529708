import { useState } from "react";
import { Badge } from "./badge/badge";
import { Button } from "./button/button";
import { Checkbox } from "./checkbox/checkbox";
import { Dropdown, DropdownOption } from "./dropdown/dropdown";
import { Icon, IconList } from "./icon/icon";
import { Input } from "./input/input";
import { QuickSearch } from "./quick_search/quick_search";
import { Tabs } from "./tabs/tabs";
import { Toggle } from "./toggle_button/Toggle";
import { Typography, TypographyProps } from "./typography/typography";
import { Autocomplete, useAutocomplete } from "./autocomplete/autocomplete";
import { SelectionGroup } from "./selection_group/selection_group";

export function LandUiView() {
    return (
        <div className="lui-p-10 lui-bg-white">
            <div>
                <Typography variant="h1" size="4xl" weight="bold">
                    Land UI
                </Typography>

                <Typography variant="p" className="lui-max-w-7xl">
                    The Land UI design system consists of custom React components and
                    Tailwind CSS utilities. All Tailwind classes are prefixed with
                    `lui-` to ensure clear identification and avoid conflicts with any
                    existing styles. All related components are under the `land_ui`
                    directory in this project
                </Typography>
            </div>

            <div className="lui-flex lui-gap-20 lui-mt-5 ">
                <div className="lui-w-72  lui-rounded-lg ">
                    <div className="lui-flex lui-flex-col lui-gap-5 lui-sticky lui-top-0">
                        <Typography variant="h2" size="3xl" weight="bold">
                            Components
                        </Typography>
                        <div className="lui-flex lui-flex-col lui-gap-5">
                            <a href="#color">Colors</a>
                            <a href="#typography">Typography</a>
                            <a href="#tabs">Tabs</a>

                            <a href="#buttons">Buttons</a>
                            <a href="#selection_group">Selection Group</a>
                            <a href="#toggle">Toggle</a>
                            <a href="#checkbox">Checkbox</a>
                            <a href="#input">Input</a>
                            <a href="#dropdown">Dropdown</a>
                            <a href="#autocomplete">Autocomplete</a>
                            <a href="#quick-search">Quick Search</a>
                            <a href="#shadow">Shadow</a>
                            <a href="#badge">Badge</a>
                            <a href="#icon">Icons</a>
                            <a href="#input">Input</a>
                        </div>
                    </div>
                </div>
                <div className="lui-flex lui-flex-col lui-gap-14 lui-mb-96 ">
                    <Colors />
                    <TypographyView />
                    <TabsView />
                    <ButtonView />
                    <SelectionGroupView />
                    <ToggleView />
                    <CheckboxView />
                    <InputView />
                    <DropdownView />
                    <AutocompleteView />
                    <QuickSearchView />
                    <BoxShadowView />
                    <BadgeView />
                    <Icons />
                </div>
            </div>
        </div>
    );
}

const OPTIONS = [
    { id: "1", label: "The Great Gatsby" },
    { id: "2", label: "To Kill a Mockingbird" },
    { id: "3", label: "1984" },
    { id: "4", label: "Harry Potter and the Sorcerer's Stone" },
    { id: "5", label: "The Catcher in the Rye" },
    { id: "6", label: "Animal Farm" },
    { id: "7", label: "The Hobbit" },
    { id: "8", label: "The Hunger Games" },
    { id: "9", label: "The Da Vinci Code" },
    { id: "10", label: "The Alchemist" },
];

function DropdownView() {
    const [selectedValue, setSelectedValue] = useState<DropdownOption>(null);
    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="dropdown" variant="h2" size="3xl" weight="bold">
                Dropdown
            </Typography>
            <div className="lui-flex lui-gap-5 lui-max-w-lg">
                <Dropdown
                    id="dropdown"
                    options={OPTIONS}
                    onSelect={(id) => setSelectedValue(id)}
                    selectedItem={selectedValue}
                />
            </div>
        </div>
    );
}

function QuickSearchView() {
    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="quick-search" variant="h2" size="3xl" weight="bold">
                Quick Search
            </Typography>
            <div className="lui-flex lui-gap-5 ">
                <QuickSearch onSearch={() => {}} />
            </div>
        </div>
    );
}

function AutocompleteView() {
    const autoComplete = useAutocomplete({
        id: "autocomplete-example",
        initialOptions: OPTIONS,
        onSearch: async (inputValue) => {
            return OPTIONS.filter((op) =>
                op.label.toLowerCase().includes(inputValue.toLowerCase()),
            );
        },
    });
    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="autocomplete" variant="h2" size="3xl" weight="bold">
                Autocomplete
            </Typography>
            <div className="lui-flex lui-gap-5 lui-max-w-lg">
                <Autocomplete {...autoComplete} />
            </div>
        </div>
    );
}

function CheckboxView() {
    const [checked, setChecked] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);

    return (
        <div className="lui-flex lui-flex-col lui-gap-4">
            <Typography id="checkbox" variant="h2" size="3xl" weight="bold">
                Checkbox
            </Typography>
            <div className="lui-flex lui-flex-col lui-gap-5">
                <Checkbox
                    id="Checkbox_1"
                    checked={checked}
                    onChange={() => {
                        setChecked(!checked);
                    }}
                />
                <Checkbox
                    id="Checkbox_2"
                    label="Enable text messages"
                    checked={checked2}
                    onChange={() => {
                        setChecked2(!checked2);
                    }}
                />
                <Checkbox
                    disabled
                    id="Checkbox_3"
                    label="Checkbox Disabled"
                    checked={checked3}
                    onChange={() => {
                        setChecked3(!checked3);
                    }}
                />
            </div>
        </div>
    );
}

function ToggleView() {
    const [toggleWithLabelChecked, setToggleWithLabelChecked] = useState(false);
    const [toggleChecked, setToggleChecked] = useState(true);
    const [plainToggleChecked, setPlainToggleChecked] = useState(false);

    return (
        <div className="lui-flex lui-flex-col lui-gap-5 lui-flex-wrap">
            <Typography id="toggle" variant="h2" size="3xl" weight="bold">
                Toggle
            </Typography>
            <div className="lui-flex lui-flex-col lui-gap-5">
                <Toggle
                    checked={plainToggleChecked}
                    onChange={() => setPlainToggleChecked(!plainToggleChecked)}
                />
                <Toggle
                    checked={toggleWithLabelChecked}
                    onChange={() => setToggleWithLabelChecked(!toggleWithLabelChecked)}
                    label="Toggle with label"
                />

                <Toggle
                    checked={toggleChecked}
                    onChange={() => setToggleChecked(!toggleChecked)}
                    label="Default checked"
                />

                <Toggle label="Disabled" disabled />
            </div>
        </div>
    );
}

function Icons() {
    return (
        <div className="lui-flex lui-flex-col lui-gap-5 lui-flex-wrap">
            <Typography id="icon" variant="h2" size="3xl" weight="bold">
                Icon
            </Typography>
            <div className="lui-flex lui-gap-20 lui-flex-wrap">
                {IconList.map((icon) => {
                    return <Icon name={icon} />;
                })}
            </div>
        </div>
    );
}

function Colors() {
    const colorTypes = ["primary", "red", "orange", "green", "gray"];
    const colorIntervals = [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
    return (
        <div className="lui-flex lui-flex-col lui-gap-10">
            <Typography id="color" variant="h2" size="3xl" weight="bold">
                Color
            </Typography>

            <div className="lui-flex lui-flex-col lui-gap-5">
                {colorTypes.map((type) => {
                    return (
                        <div key={type}>
                            <Typography weight="bold" size="lg">
                                {type}
                            </Typography>
                            <div className="lui-flex lui-gap-5 lui-mt-2">
                                {colorIntervals.map((i) => {
                                    return (
                                        <div key={i}>
                                            <div
                                                style={{
                                                    backgroundColor: `var(--lui-${type}-${i})`,
                                                }}
                                                className="lui-rounded-lg lui-flex lui-items-center lui-justify-center lui-text-white lui-bg-primary-500 lui-w-16 lui-h-10"
                                            >
                                                <Typography
                                                    color="primary-600"
                                                    size="sm"
                                                >
                                                    {i}
                                                </Typography>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function TypographyView() {
    let size: TypographyProps["size"][] = [
        "4xl",
        "3xl",
        "2xl",
        "xl",
        "lg",
        "md",
        "sm",
        "xs",
    ];
    let weight: TypographyProps["weight"][] = ["regular", "medium", "bold"];

    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="typography" variant="h2" weight="bold" size="3xl">
                Typography
            </Typography>
            <div className="lui-flex lui-gap-20">
                {weight.map((w) => {
                    return (
                        <div key={w} className="lui-flex lui-flex-col lui-gap-5">
                            {size.map((size) => {
                                return (
                                    <Typography
                                        key={size}
                                        variant={"div"}
                                        weight={w}
                                        size={size}
                                    >
                                        Text {size}
                                    </Typography>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function TabsView() {
    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="tabs" variant="h2" size="3xl" weight="bold">
                Tabs
            </Typography>

            <div className="lui-max-w-3xl ">
                <Tabs
                    options={[
                        {
                            title: "Summary",
                            content: <TabBodyExample title="Summary" />,
                        },
                        {
                            title: "Parcel",
                            content: <TabBodyExample title="Parcel" />,
                        },
                        {
                            title: "Owner",
                            content: <TabBodyExample title="Owner" />,
                        },
                        {
                            title: "Structure",
                            content: <TabBodyExample title="Structure" />,
                        },
                        {
                            title: "Metrics",
                            content: <TabBodyExample title="Metrics" />,
                        },
                        {
                            title: "Misc",
                            content: <TabBodyExample title="Misc" />,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

function TabBodyExample({ title }: { title: string }) {
    return (
        <>
            <Typography variant="p" size="xl">
                {title}
            </Typography>
            <Typography variant="p">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam
                cupiditate ipsa praesentium cumque dolore, quas distinctio sit odit
                explicabo exercitationem quidem quibusdam labore dolorum maiores
                veritatis dolorem doloribus quod? Odio!
            </Typography>
        </>
    );
}

function ButtonView() {
    return (
        <>
            <div className="lui-flex lui-flex-col lui-gap-5">
                <Typography id="buttons" variant="h2" size="3xl" weight="bold">
                    Button
                </Typography>
                <div className="lui-flex lui-gap-5">
                    <Button>Primary</Button>
                    <Button disabled>Primary Disabled</Button>

                    <Button variant="secondary">Secondary</Button>
                    <Button variant="secondary" disabled>
                        Secondary Disabled
                    </Button>

                    <Button variant="inline">Inline</Button>
                    <Button variant="inline" disabled>
                        Inline Disable
                    </Button>
                </div>
            </div>
            <div className="lui-flex lui-flex-col lui-gap-5">
                <Typography variant="h2" size="2xl" weight="bold">
                    Button With Icons
                </Typography>

                <div className="lui-grid lui-grid-cols-3">
                    <div className="lui-flex lui-gap-2 lui-px-2">
                        <Button variant="primary" icon="Filter" />
                        <Button variant="primary" icon="Filter">
                            Icon primary
                        </Button>
                        <Button variant="primary">
                            <span className="lui-flex lui-gap-2 lui-items-center">
                                Icon Right{" "}
                                <Icon
                                    name="RightArrow"
                                    color="inherit"
                                    className="lui-ml-2"
                                />
                            </span>
                        </Button>
                    </div>
                    <div className="lui-flex lui-gap-2 lui-px-2">
                        <Button variant="secondary" icon="Filter" />
                        <Button variant="secondary" icon="Filter">
                            Icon secondary
                        </Button>
                        <Button variant="secondary">
                            <span className="lui-flex lui-gap-2 lui-items-center">
                                Icon Right{" "}
                                <Icon
                                    name="RightArrow"
                                    color="inherit"
                                    className="lui-ml-2"
                                />
                            </span>
                        </Button>
                    </div>
                    <div className="lui-flex lui-gap-2 lui-px-2">
                        <Button variant="inline" icon="Filter" />
                        <Button variant="inline" icon="Filter">
                            Icon inline
                        </Button>
                        <Button variant="inline">
                            <span className="lui-flex lui-gap-2 lui-items-center">
                                Icon Right{" "}
                                <Icon
                                    name="RightArrow"
                                    color="inherit"
                                    className="lui-ml-2"
                                />
                            </span>
                        </Button>
                    </div>
                </div>
            </div>

            <div className="lui-flex lui-flex-col lui-gap-5">
                <Typography variant="h2" size="2xl" weight="bold">
                    Full Width Buttons
                </Typography>
                <Button fullWidth>Full Width button</Button>
                <Button fullWidth variant="secondary">
                    Full Width button
                </Button>
                <Button fullWidth variant="inline">
                    Full Width button
                </Button>
            </div>
        </>
    );
}

function BoxShadowView() {
    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="shadow" variant="h2" size="3xl" weight="bold">
                Shadow
            </Typography>
            <div className="lui-flex lui-gap-10 lui-bg-gray-0 lui-p-9">
                <div className="lui-w-36 lui-bg-white lui-h-36 lui-shadow-sm lui-rounded-lg"></div>
                <div className="lui-w-36 lui-bg-white lui-h-36 lui-shadow-md lui-rounded-lg"></div>
                <div className="lui-w-36 lui-bg-white lui-h-36 lui-shadow-lg lui-rounded-lg"></div>
                <div className="lui-w-36 lui-bg-white lui-h-36 lui-shadow-xl lui-rounded-lg"></div>
            </div>
        </div>
    );
}

function BadgeView() {
    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="Badge" variant="h2" size="3xl" weight="bold">
                Badge
            </Typography>
            <div className="lui-flex lui-gap-10 lui-bg-gray-0 lui-p-4">
                <Badge variant="info">Info</Badge>
                <Badge variant="danger">danger</Badge>
                <Badge variant="warning">Warning</Badge>
            </div>
        </div>
    );
}

function InputView() {
    return (
        <div className="lui-flex lui-flex-col lui-gap-5">
            <Typography id="input" variant="h2" size="3xl" weight="bold">
                Input
            </Typography>
            <div className="lui-grid lui-grid-cols-2 lui-gap-5 lui-max-w-2xl">
                <Input label="Input" placeholder="Placeholder" />
                <Input
                    label="Input with action & Icon"
                    icon="Pin"
                    placeholder="County or State..."
                    action="Exclude ZIPs"
                />

                <Input
                    label="Input Error"
                    icon="Pin"
                    placeholder="County or State..."
                    error="Error message"
                />

                <Input
                    label="Input with only action"
                    icon="Pin"
                    placeholder="County or State..."
                    action="Exclude ZIPs"
                />
            </div>
        </div>
    );
}

function SelectionGroupView() {
    const [selected, setSelected] = useState(null);
    const [selected2, setSelected2] = useState(null);
    const options = [
        {
            id: 1,
            title: "Standard",
            subtitle: "1 credit / row",
            info: "info 1",
        },
        {
            id: 2,
            title: "Standard",
            subtitle: "1 credit / row",
            info: "info 2",
        },
        {
            id: 3,
            title: "Standard",
            subtitle: "1 credit / row",
        },
    ];
    return (
        <div className="lui-flex lui-flex-col lui-gap-6 lui-max-w-xl">
            <Typography id="selection_group" variant="h2" size="3xl" weight="bold">
                Selection Group
            </Typography>
            <div className="lui-flex lui-flex-col lui-gap-7">
                <div className="lui-flex lui-flex-col lui-gap-3">
                    <Typography variant="h3" size="2xl" weight="bold">
                        Horizontal Group
                    </Typography>
                    <SelectionGroup
                        horizontal
                        options={options}
                        selected={selected}
                        onSelect={(selected) => setSelected(selected)}
                    />
                </div>
                <div className="lui-flex lui-flex-col lui-gap-3">
                    <Typography variant="h3" size="2xl" weight="bold">
                        Vertical Group
                    </Typography>
                    <SelectionGroup
                        options={options}
                        selected={selected2}
                        onSelect={(selected) => setSelected2(selected)}
                    />
                </div>
            </div>
        </div>
    );
}
