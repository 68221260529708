import React, { useContext, useState, useEffect } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { fetch } from "functions";

import type { SavedListResult } from "../types";

import { ParcelViewerContext } from "../context";
import MapIconButton from "../ui/map_button";
import ReactMapControl from "../ui/map_control";

interface SavedListsControlProps {
    open: boolean;
    setOpen: (value: boolean) => void;
}

export default function SavedListsControl({ open, setOpen }: SavedListsControlProps) {
    return (
        <ReactMapControl position="top-right">
            <>
                <MapIconButton
                    icon="fa-solid fa-list"
                    title="Saved Lists"
                    enableTooltip={!open}
                    onClick={() => setOpen(!open)}
                />
                {open && <ViewSavedListsModal setOpen={setOpen} />}
            </>
        </ReactMapControl>
    );
}

interface ViewSavedListsModalProps {
    setOpen: (value: boolean) => void;
}

function ViewSavedListsModal({ setOpen }: ViewSavedListsModalProps) {
    const { setSavedList } = useContext(ParcelViewerContext);
    const [savedListsResult, setSavedListsResult] = useState<SavedListResult>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSavedLists = async () => {
            setLoading(true);

            try {
                const savedListsResult: SavedListResult = await fetch(
                    `/api/property/savedlists/`,
                );
                setSavedListsResult(savedListsResult);
            } catch (xhr) {
                console.log("Error fetching saved lists", xhr);
            }

            setLoading(false);
        };

        fetchSavedLists();
    }, []);

    const savedLists = savedListsResult?.results || [];

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Saved Lists
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading && "Loading..."}
                {savedLists.map((savedList) => (
                    <button
                        key={savedList.id}
                        className="btn btn-primary"
                        onClick={() => setSavedList(savedList)}
                    >
                        {savedList.title}
                    </button>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
