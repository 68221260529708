"use client";

import { forwardRef } from "react";
import { Typography } from "../typography/typography";
import "./input.css";
import { Icon, IconNameType } from "../icon/icon";
import clsx from "clsx";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    info?: React.ReactNode;
    placeholder?: string;
    error?: React.ReactNode;
    type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
    step?: React.InputHTMLAttributes<HTMLInputElement>["step"];
    icon?: IconNameType;
    action?: React.ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        { label, info, icon, placeholder, error, type = "text", action, ...props },
        ref,
    ) => {
        const { className, ...rest } = props;

        return (
            <span className="lui-input__container">
                {(label || action) && (
                    <span className="lui-flex lui-justify-between">
                        {label && <Typography variant="label">{label}</Typography>}
                        {action && (
                            <Typography variant="span" color="primary-500">
                                {action}
                            </Typography>
                        )}
                    </span>
                )}

                <span
                    className={clsx(
                        "lui-px-4 lui-py-3 lui-input__border lui-rounded-[100px]",
                        {
                            "lui-pl-11": icon,
                            "lui-border-red-700": error,
                            "lui-border-gray-300 focus-within:lui-border-gray-700 hover:lui-border-gray-700":
                                !error,
                        },
                    )}
                >
                    {icon && (
                        <Icon
                            name={icon}
                            className="lui-absolute lui-left-4"
                            color="gray-700"
                        />
                    )}
                    <input
                        ref={ref}
                        className={clsx("lui-input__input-field", className)}
                        type={type}
                        placeholder={placeholder}
                        {...rest}
                    />
                </span>
                {(!!error || !!info) && (
                    <span className="lui-mt-1">
                        {error && (
                            <Typography
                                size="sm"
                                variant="span"
                                color="red-700"
                                className="lui-flex lui-gap-1.5 lui-items-center"
                            >
                                <Icon name="Announcement" color="inherit" size="sm" />
                                {error}
                            </Typography>
                        )}

                        {info && (
                            <Typography
                                size="sm"
                                variant="span"
                                color="gray-700"
                                className="lui-flex lui-gap-1.5 lui-items-center"
                            >
                                {info}
                            </Typography>
                        )}
                    </span>
                )}
            </span>
        );
    },
);
