import React, { useState, useEffect } from "react";

import Dropdown from "react-bootstrap/Dropdown";

import MapIconButton from "../ui/map_button";
import ReactMapControl from "../ui/map_control";

export const DEFAULT_MAP_STYLE =
    "mapbox://styles/kmlandinsights/clyvxfp7m005001px4kv01z02";

export default function LayerControl({ setMapStyle }) {
    const [open, setOpen] = useState(false);
    return (
        <ReactMapControl position="top-right">
            <>
                <MapIconButton
                    icon="fa-solid fa-map"
                    title="Map Layers"
                    enableTooltip={!open}
                    onClick={() => setOpen(!open)}
                />
                {open && <LayerPanel setMapStyle={setMapStyle} setOpen={setOpen} />}
            </>
        </ReactMapControl>
    );
}

function LayerPanel({ setMapStyle, setOpen }) {
    const mapStyles = [
        { mapStyle: DEFAULT_MAP_STYLE, name: "Land Insights" },
        { mapStyle: "mapbox://styles/mapbox/standard", name: "Standard" },
        { mapStyle: "mapbox://styles/mapbox/standard-satellite", name: "Satellite" },
        { mapStyle: "mapbox://styles/mapbox/streets-v12", name: "Streets" },
        { mapStyle: "mapbox://styles/mapbox/outdoors-v12", name: "Outdoors" },
        { mapStyle: "mapbox://styles/mapbox/light-v11", name: "Light" },
        { mapStyle: "mapbox://styles/mapbox/dark-v11", name: "Dark" },
        { mapStyle: "mapbox://styles/mapbox/satellite-v9", name: "Satellite v9" },
        {
            mapStyle: "mapbox://styles/mapbox/satellite-streets-v12",
            name: "Satellite Streets",
        },
        {
            mapStyle: "mapbox://styles/mapbox/navigation-day-v1",
            name: "Navigation Day",
        },
        {
            mapStyle: "mapbox://styles/mapbox/navigation-night-v1",
            name: "Navigation Night",
        },
    ];

    const _setMapStyle = (mapStyle) => {
        setMapStyle(mapStyle);
        setOpen(false);
    };

    return (
        <div id="map-layers">
            <Dropdown.Menu show className="border border-dark">
                {mapStyles.map(({ mapStyle, name }, i) => (
                    <Dropdown.Item key={i} onClick={() => _setMapStyle(mapStyle)}>
                        {name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </div>
    );
}
