import { clsx } from "clsx";

import "./badge.css";
import { Typography } from "../typography/typography";
import { LuiColors } from "../color/color";

export type BadgeProps = {
    children: React.ReactNode;
    variant: "danger" | "warning" | "info";
    className?: string;
};

export function Badge({ className, children, variant = "info" }: BadgeProps) {
    const variantColor: Record<BadgeProps["variant"], LuiColors> = {
        warning: "orange-900",
        danger: "red-800",
        info: "primary-800",
    };
    const variantBgColor: Record<BadgeProps["variant"], string> = {
        warning: "lui-bg-orange-100",
        danger: "lui-bg-red-100",
        info: "lui-bg-primary-100",
    };

    return (
        <Typography
            size="xs"
            color={variantColor[variant]}
            className={clsx(
                className,
                variantBgColor[variant],
                "lui-badge",
                "lui-bg-orange-200",
            )}
        >
            {children}
        </Typography>
    );
}
