"use client";

import clsx from "clsx";
import { forwardRef } from "react";
import "./button.css";
import { Typography } from "../typography/typography";
import { Icon, IconNameType } from "../icon/icon";

export interface ButtonProps {
    disabled?: boolean;
    loading?: boolean;
    content?: string;
    fullWidth?: boolean;
    variant?: "primary" | "secondary" | "inline";
    type?: "button" | "submit";
    onClick?: () => void;
    children?: React.ReactNode;
    className?: string;
    icon?: IconNameType;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            disabled,
            loading,
            variant = "primary",
            icon,
            fullWidth,
            type = "button",
            onClick,
            children,
            className,
        },
        ref,
    ) => {
        return (
            <button
                ref={ref}
                className={clsx(
                    "lui-button",
                    `lui-button--${variant}`,
                    {
                        "lui-button--full-width": fullWidth,
                        "lui-button--icon": icon,
                        "lui-button--circle": icon && !children,
                    },
                    className,
                )}
                disabled={disabled || loading}
                onClick={onClick}
                type={type}
            >
                {icon && <Icon name={icon} color="inherit" />}

                {loading && "Loading..."}

                {children && (
                    <Typography
                        weight="bold"
                        color="inherit"
                        className={clsx({ "lui-ml-2": icon })}
                    >
                        {children}
                    </Typography>
                )}
            </button>
        );
    },
);
