const USD = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const USDRounded = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
});

export function formatMoneyCents(cents) {
    const dollars = cents / 100;
    return USD.format(dollars);
}

export function formatMoneyRounded(dollars) {
    return USDRounded.format(dollars);
}

export function formatCredits(credits) {
    return `${formatNumber(credits)} credit${credits != 1 ? "s" : ""}`;
}

export function formatDate(dateString) {
    if (!dateString) {
        return "";
    }
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
    });
}

export function formatTimestamp(timestamp) {
    // Create a new Date object with the Unix timestamp (in milliseconds)
    const date = new Date(timestamp);

    // Format the date into a readable string
    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Set to true if you prefer a 12-hour format
    };

    return date.toLocaleString(undefined, options);
}

export function formatPercent(num) {
    return `${num}%`;
}

export function formatNumber(num) {
    return num.toLocaleString();
}

export function formatBoolean(val) {
    return !!val ? "Yes" : "No";
}

export function zeroPad(num, places) {
    return String(num).padStart(places, "0");
}

const irregularNouns = {
    miss: "misses",
};

export function pluralize(word, count) {
    if (count === 1) {
        return word;
    }
    if (irregularNouns[word.toLowerCase()]) {
        return irregularNouns[word.toLowerCase()];
    }
    return word + "s";
}

export function capitalize(str) {
    if (!str) {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}
