import React, { useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";

import MapIconButton from "../ui/map_button";
import ReactMapControl from "../ui/map_control";

export default function DataControl({
    showCountyLayer,
    setShowCountyLayer,
    showZipLayer,
    setShowZipLayer,
    showDebugBoundaries,
    setShowDebugBoundaries,
    showWetlandsLayer,
    setShowWetlandsLayer,
    showBuildingsLayer,
    setShowBuildingsLayer,
    showRoadsLayer,
    setShowRoadsLayer,
}) {
    const [open, setOpen] = useState(false);
    return (
        <ReactMapControl position="top-right">
            <>
                <MapIconButton
                    icon="fa-solid fa-layer-group"
                    title="Data Layers"
                    enableTooltip={!open}
                    onClick={() => setOpen(!open)}
                />
                {open && (
                    <DataPanel
                        showCountyLayer={showCountyLayer}
                        setShowCountyLayer={setShowCountyLayer}
                        showZipLayer={showZipLayer}
                        setShowZipLayer={setShowZipLayer}
                        showDebugBoundaries={showDebugBoundaries}
                        setShowDebugBoundaries={setShowDebugBoundaries}
                        showWetlandsLayer={showWetlandsLayer}
                        setShowWetlandsLayer={setShowWetlandsLayer}
                        showBuildingsLayer={showBuildingsLayer}
                        setShowBuildingsLayer={setShowBuildingsLayer}
                        showRoadsLayer={showRoadsLayer}
                        setShowRoadsLayer={setShowRoadsLayer}
                    />
                )}
            </>
        </ReactMapControl>
    );
}

function DataPanel({
    showCountyLayer,
    setShowCountyLayer,
    showZipLayer,
    setShowZipLayer,
    showDebugBoundaries,
    setShowDebugBoundaries,
    showWetlandsLayer,
    setShowWetlandsLayer,
    showBuildingsLayer,
    setShowBuildingsLayer,
    showRoadsLayer,
    setShowRoadsLayer,
}) {
    const actions = [
        {
            name: "Toggle County Layer",
            visible: showCountyLayer,
            onClick: () => setShowCountyLayer(!showCountyLayer),
        },
        {
            name: "Toggle ZIP Layer",
            visible: showZipLayer,
            onClick: () => setShowZipLayer(!showZipLayer),
        },
        {
            name: "Toggle Tile Boundaries",
            visible: showDebugBoundaries,
            onClick: () => setShowDebugBoundaries(!showDebugBoundaries),
        },
        {
            name: "Toggle Wetlands Layer",
            visible: showWetlandsLayer,
            onClick: () => setShowWetlandsLayer(!showWetlandsLayer),
        },
        {
            name: "Toggle Buildings Layer",
            visible: showBuildingsLayer,
            onClick: () => setShowBuildingsLayer(!showBuildingsLayer),
        },
        {
            name: "Toggle Roads Layer",
            visible: showRoadsLayer,
            onClick: () => setShowRoadsLayer(!showRoadsLayer),
        },
    ];

    return (
        <div id="map-layers">
            <Dropdown.Menu show className="border border-dark">
                {actions.map(({ name, visible, onClick }, i) => {
                    const icon =
                        "fa-regular" + (visible ? " fa-square-check" : " fa-square");
                    return (
                        <Dropdown.Item key={i} onClick={onClick}>
                            <i className={icon}></i> {name}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </div>
    );
}
