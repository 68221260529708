import { Icon } from "../icon/icon";
import { Typography } from "../typography/typography";
import clsx from "clsx";

interface Option {
    id: number;
    title: string;
    subtitle: React.ReactNode;
    info?: string;
}

interface SelectionGroupProps {
    options: Option[];
    selected: number;
    onSelect: (id: number) => void;
    horizontal?: boolean;
}

export function SelectionGroup({
    options = [],
    selected,
    onSelect,
    horizontal = false,
}: SelectionGroupProps) {
    let handleClick = (id: number) => {
        if (selected === id) {
            onSelect(null);
        } else {
            onSelect(id);
        }
    };

    return (
        <div
            className={clsx("lui-flex lui-gap-3", {
                "lui-flex-col": !horizontal,
            })}
        >
            {options.map((option) => {
                const isSelected = selected === option.id;

                return (
                    <button
                        key={option.id}
                        onClick={() => handleClick(option.id)}
                        className={clsx(
                            `lui-rounded-full lui-group lui-w-full  lui-border lui-border-solid lui-border-gray-100 hover:lui-bg-gray-50 lui-transition-colors lui-duration-300`,
                            "focus-visible:lui-outline-none focus:lui-outline-none",
                            {
                                "lui-border-2 lui-border-primary-500 lui-bg-primary-50 hover:lui-bg-primary-50":
                                    isSelected,
                                "lui-bg-white": !isSelected,
                            },
                        )}
                    >
                        <span className="lui-flex lui-gap-4 lui-justify-between lui-px-5 lui-py-2 lui-text-start focus-visible:lui-outline-none focus:lui-outline-none">
                            <span className="lui-flex lui-flex-col">
                                <Typography
                                    variant="span"
                                    weight="medium"
                                    size="md"
                                    color={isSelected ? "primary-500" : "gray-700"}
                                >
                                    {option.title}
                                </Typography>
                                <Typography variant="span" size="xs" color="gray-700">
                                    {option.subtitle}
                                </Typography>
                            </span>
                            {!!option.info && (
                                <span
                                    className={clsx(
                                        `lui-flex lui-items-center lui-text-gray-400 group-hover:lui-text-gray-700`,
                                        {
                                            "lui-text-gray-400 group-hover:lui-text-gray-400":
                                                isSelected,
                                        },
                                    )}
                                    title={option.info}
                                >
                                    <Icon name="InfoCircle" size="md" color="inherit" />
                                </span>
                            )}
                        </span>
                    </button>
                );
            })}
        </div>
    );
}
