import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Modal from "react-bootstrap/Modal";

import { FeatureCollection } from "geojson";
import { LngLatBounds } from "mapbox-gl";
import type {
    CircleLayer,
    FillLayer,
    LineLayer,
    MapLayerMouseEvent,
    MapRef,
    SourceProps,
    SymbolLayer,
} from "react-map-gl";
import Map, { FullscreenControl, Layer, NavigationControl, Source } from "react-map-gl";

import { fetch, isLoggedIn, logout } from "functions";
import { API_URL, MAPBOX_TOKEN, TILER_URL } from "settings";

import type { County, MapFilter, Parcel, SavedList, SearchResult, User } from "./types";

import { ParcelViewerContext, UserContext } from "./context";
import DataControl from "./controls/data";
import ParcelDetail from "./controls/detail";
import DrawControl from "./controls/draw";
import { FilterPanel } from "./controls/filter";
import HelpControl from "./controls/help";
import InspectControl from "./controls/inspect";
import LayerControl, { DEFAULT_MAP_STYLE } from "./controls/layer";
import SavedListsControl from "./controls/lists";
import ToolsControl from "./controls/tools";
import { MapHeader } from "./map_header";

// Mapbox filters
const COUNTY_FILTER = [
    "all",
    ["==", ["get", "level"], 2],
    ["==", ["get", "iso_a2"], "US"],
];
const ZIP_FILTER = [
    "all",
    ["==", ["get", "level"], 1],
    ["==", ["get", "iso_a2"], "US"],
];

const initialViewState = {
    longitude: -80.49238,
    latitude: 34.30861,
    zoom: 14,
};

const maptilerSource: SourceProps = {
    id: "maptiler_source",
    type: "vector",
    tiles: [`${API_URL}/tiles/countries/{z}/{x}/{y}.pbf`],
    maxzoom: 10,
};

const parcelSource: SourceProps = {
    id: "tiler_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/parcels/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};

const wetlandsSource: SourceProps = {
    id: "wetland_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/wetlands/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};

const wetlandsFill: FillLayer = {
    id: "wetland_identify",
    type: "fill",
    "source-layer": "wetlands",
    paint: { "fill-color": "#0067c9", "fill-opacity": 0.5 },
};

const wetlandsOutline: LineLayer = {
    id: "wetlands_outline",
    type: "line",
    "source-layer": "wetlands",
    paint: {
        "line-width": 2,
        "line-color": "#153f76",
    },
};

const buildingsSource: SourceProps = {
    id: "buildings_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/buildings/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};

const buildingsOutline: LineLayer = {
    id: "buildings_outline",
    type: "line",
    "source-layer": "buildings",
    paint: {
        "line-width": 1,
        "line-color": "#39ff14",
    },
};

const roadsSource: SourceProps = {
    id: "roads_source",
    type: "vector",
    tiles: [`${TILER_URL}/maps/roads/{z}/{x}/{y}.pbf`],
    promoteId: "id",
};

const roadsOutline: LineLayer = {
    id: "roads_outline",
    type: "line",
    "source-layer": "roads",
    paint: {
        "line-width": 2,
        "line-color": "#fff314",
    },
};

// Transparent layer to enable click-to-identify feature
const countyIdentify: FillLayer = {
    id: "county_identify",
    type: "fill",
    "source-layer": "administrative",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
    filter: COUNTY_FILTER,
};

// County data layer outline
const countyOutline: LineLayer = {
    id: "county_outline",
    type: "line",
    "source-layer": "administrative",
    paint: {
        "line-width": 2,
        "line-color": "#f00",
    },
    filter: COUNTY_FILTER,
};

// Highlight county outline
const countyHighlightOutline: LineLayer = {
    id: "county_highlight_outline",
    type: "line",
    "source-layer": "administrative",
    paint: {
        "line-width": 3,
        "line-color": "#ff00f2",
    },
    filter: COUNTY_FILTER,
};

// Highlight county shadow
const countyHighlightShadow: FillLayer = {
    id: "county_highlight_shadow",
    type: "fill",
    "source-layer": "administrative",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};

// Transparent layer to enable click-to-identify feature
const zipIdentify: FillLayer = {
    id: "zip_identify",
    type: "fill",
    "source-layer": "postal",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
    filter: ZIP_FILTER,
};

// Zip data layer outline
const zipOutline: LineLayer = {
    id: "zip_outline",
    type: "line",
    "source-layer": "postal",
    paint: {
        "line-width": 1,
        "line-color": "#00f",
    },
    filter: ZIP_FILTER,
};

// Highlight zip outline
const zipHighlightOutline: LineLayer = {
    id: "zip_highlight_outline",
    type: "line",
    "source-layer": "postal",
    paint: {
        "line-width": 3,
        "line-color": "#ff00f2",
    },
    filter: ZIP_FILTER,
};

// Highlight zip shadow
const zipHighlightShadow: FillLayer = {
    id: "zip_highlight_shadow",
    type: "fill",
    "source-layer": "postal",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};

// Transparent layer to enable click-to-identify feature
const parcelIdentify: FillLayer = {
    id: "parcel_identify",
    type: "fill",
    "source-layer": "parcels",
    paint: { "fill-color": "#000000", "fill-opacity": 0 },
};

// Highlight parcel shadow
const parcelHighlightShadow: FillLayer = {
    id: "parcel_highlight_shadow",
    type: "fill",
    "source-layer": "parcels",
    paint: { "fill-color": "#000000", "fill-opacity": 0.5 },
};

// Parcel outline layer
const parcelHighlightOutline: LineLayer = {
    id: "parcel_highlight_line",
    type: "line",
    "source-layer": "parcels",
    paint: {
        "line-width": [
            "interpolate",
            ["linear"],
            ["zoom"],
            12,
            0.5, // Line width at zoom 12
            18,
            [
                "case",
                ["boolean", ["feature-state", "searchResultMatch"], false],
                4, // Line width for search results at zoom 18
                1, // Line width for non-search results at zoom 18
            ],
        ],
        "line-color": [
            "case",
            ["boolean", ["feature-state", "searchResultMatch"], false],
            "#ff00f2", // Line color for search results
            "#ffa500", // Line color for non-search results
        ],
    },
};

// Selected parcel layer
const parcelSelectedOutline: LineLayer = {
    id: "parcel_selected_outline",
    type: "line",
    "source-layer": "parcels",
    paint: {
        "line-width": 4,
        "line-color": "#17c1e8",
    },
};

// Search results cluster layer
const searchResultsCluster: CircleLayer = {
    id: "search_results_cluster",
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
        "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6", // Color for 0-100
            100,
            "#f1f075", // Color for 100-750
            750,
            "#f28cb1", // Color for 750+
        ],
        "circle-radius": [
            "step",
            ["get", "point_count"], // input value
            20, // Radius for 0-100
            100,
            30, // Radius for 100-750
            750,
            40, // Radius for 750+
        ],
    },
};
const searchResultsClusterLabel: SymbolLayer = {
    id: "search_results_cluster_label",
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
        "text-field": ["get", "point_count"],
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-size": 12,
    },
    paint: {
        "text-color": "black",
        "text-opacity": ["step", ["zoom"], 1, 14, 0],
    },
};

declare global {
    interface Window {
        setShowWetlandsLayer: any;
    }
}

export default function ParcelViewer() {
    const [user, setUser] = useState<User>();

    useEffect(() => {
        if (!isLoggedIn()) {
            logout();
            return;
        }

        const fetchUser = async () => {
            try {
                const user = await fetch("/user/user/");
                setUser(user);
            } catch (xhr) {
                // TODO: show error modal
                console.error("Error loading user", xhr);
            }
        };

        fetchUser();
    }, [setUser]);

    const context = { user };

    return (
        <UserContext.Provider value={context}>
            {user && !user.has_paid_subscription && <SubscriptionRequiredModal />}
            <ParcelViewerMap />
        </UserContext.Provider>
    );
}

function SubscriptionRequiredModal() {
    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Subscription Required
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <p>There's a problem with your subscription.</p>
                <p>
                    If you canceled and are looking to resubscribe, contact{" "}
                    <a href="mailto:hello@landinsights.co">hello@landinsights.co</a>
                </p>
            </Modal.Body>
        </Modal>
    );
}

function ParcelViewerMap() {
    const mapRef = useRef<MapRef>();

    // Map controls UI state
    const [mapStyle, _setMapStyle] = useState(DEFAULT_MAP_STYLE);
    const [styleLoaded, setStyleLoaded] = useState(true);
    const [showFilterPanel, setShowFilterPanel] = useState(true);
    const [showSavedLists, setShowSavedLists] = useState(false);
    const [showCountyLayer, setShowCountyLayer] = useState(false);
    const [showZipLayer, setShowZipLayer] = useState(false);
    const [showDebugBoundaries, setShowDebugBoundaries] = useState(false);
    const [showWetlandsLayer, setShowWetlandsLayer] = useState(false);
    const [showBuildingsLayer, setShowBuildingsLayer] = useState(false);
    const [showRoadsLayer, setShowRoadsLayer] = useState(false);

    // Map features state
    const [county, setCounty] = useState<County>();
    const [activeParcelID, setActiveParcelID] = useState<number>(0);
    const [searchResult, _setSearchResult] = useState<SearchResult>();

    // Handle toggle debug tile boundaries option
    useEffect(() => {
        if (mapRef.current) {
            const map = mapRef.current.getMap();
            map.showTileBoundaries = showDebugBoundaries;
        }
    }, [mapRef, showDebugBoundaries]);

    // Render no features
    const emptyFilter = ["==", "code", ""];

    // mapFilter is the active map feature (parcel/county/zip)
    const [mapFilter, setMapFilter] = useState<MapFilter>();
    // savedList is the active Saved List
    const [savedList, setSavedList] = useState<SavedList>();

    const setSearchResult = useCallback(
        (searchResult: SearchResult) => {
            _setSearchResult(searchResult);

            const parcels = searchResult?.results || [];

            // Clear prior selected zip/county
            setMapFilter(null);

            // Fit map bounds to search results extent
            const bbox = calculateBounds(parcels);
            if (bbox) {
                mapRef.current.fitBounds(bbox, {
                    padding: 100,
                    duration: 0,
                });
            }

            // Reset feature state of parcels layer
            mapRef.current.removeFeatureState({
                source: "tiler_source",
                sourceLayer: "parcels",
            });

            // Set feature state of each parcel in parcels layer if the ID exists in parcels searchResults
            parcels.forEach((parcel: Parcel) => {
                mapRef.current.setFeatureState(
                    {
                        source: "tiler_source",
                        sourceLayer: "parcels",
                        id: parcel.PropertyID,
                    },
                    { searchResultMatch: true },
                );
            });
        },
        [mapRef, _setSearchResult, setMapFilter],
    );

    // Handle switching map layers. This is a destructive process in Mapbox GL
    // which is not gracefully handled by react-map-gl. Sources and layers must
    // be removed and then readded after styles are done loading to avoid
    // "Style is not done loading" errors.
    // Ref: https://github.com/visgl/react-map-gl/issues/1122
    const setMapStyle = useCallback(
        (mapStyle: string) => {
            const map = mapRef.current.getMap();

            // Restore map layers when styles are done loading
            map.once("style.load", () => {
                setStyleLoaded(true);
            });

            // Restore map feature state when tiles have reloaded
            map.once("idle", () => {
                setSearchResult(searchResult);
            });

            // Remove map layers
            setStyleLoaded(false);

            _setMapStyle(mapStyle);
        },
        [mapRef, searchResult, setSearchResult],
    );

    const onClick = (e: MapLayerMouseEvent) => {
        const feature = e.features && e.features[0];

        // Toggle active feature
        const id = parseInt(`${feature?.id}`, 10);
        setActiveParcelID((oldValue) => (id === oldValue ? null : id));

        // Clear map filter if active parcel changed
        if (id !== activeParcelID) {
            setMapFilter(null);
        }
    };

    const activeParcelFilter = useMemo(
        () => ["==", "id", activeParcelID || ""],
        [activeParcelID],
    );

    // Set body class name on mount
    useEffect(() => {
        document.body.className = "parcel-viewer";
        return () => {
            // Clear class name on dismount
            document.body.className = "";
        };
    }, []);

    const context = { setMapFilter, county, setCounty, savedList, setSavedList };

    // Apply map filter
    let zipFilter = emptyFilter;
    let countyFilter = emptyFilter;
    let parcelFilter = emptyFilter;
    let identifyFilter = emptyFilter;
    if (mapFilter) {
        const { identifyLayer, filter, inverseFilter } = mapFilter;
        identifyFilter = filter;
        if (identifyLayer === "county_identify") {
            countyFilter = inverseFilter;
        } else if (identifyLayer === "zip_identify") {
            zipFilter = inverseFilter;
        } else if (identifyLayer === "parcel_identify") {
            parcelFilter = inverseFilter;
        }
    }

    const searchResultGeojson: FeatureCollection = {
        type: "FeatureCollection",
        features:
            searchResult?.results
                ?.filter((parcel) => parcel?.point)
                ?.map((parcel) => ({
                    id: parcel.PropertyID,
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: parcel.point,
                    },
                    properties: {},
                })) || [],
    };

    const onError = (e: any) => {
        if (e.message === "Map is not supported by this browser") {
            // Handle Web GL init error raised by react-map-gl
            // TODO: friendly error message
            console.log("WebGL init error. Refresh page to continue.");
        } else if (e.error?.status === 400) {
            // Ignore HTTP 400 Bad Request tile requests
        } else {
            console.error(e);
        }
    };

    const sourcesAndLayers = styleLoaded && (
        <>
            <Source {...parcelSource}>
                <Layer {...parcelIdentify} />
                <Layer {...parcelHighlightShadow} filter={parcelFilter} />
                <Layer {...parcelHighlightOutline} />
                <Layer {...parcelSelectedOutline} filter={activeParcelFilter} />
            </Source>
            <Source {...wetlandsSource}>
                {showWetlandsLayer && <Layer {...wetlandsFill} />}
                {showWetlandsLayer && <Layer {...wetlandsOutline} />}
            </Source>
            <Source {...buildingsSource}>
                {showBuildingsLayer && <Layer {...buildingsOutline} />}
            </Source>
            <Source {...roadsSource}>
                {showRoadsLayer && <Layer {...roadsOutline} />}
            </Source>
            <Source
                type="geojson"
                data={searchResultGeojson}
                cluster={true}
                clusterMaxZoom={12}
                tolerance={0.5}
            >
                <Layer {...searchResultsCluster} />
                <Layer {...searchResultsClusterLabel} />
            </Source>
            {/*<Source type="geojson" data={searchResultGeojson}>
                <Layer {...searchResultPoints} />
                </Source>*/}
            <Source {...maptilerSource}>
                {showCountyLayer && <Layer {...countyOutline} />}
                {showZipLayer && <Layer {...zipOutline} />}
                <Layer {...countyIdentify} />
                <Layer {...countyHighlightOutline} filter={identifyFilter} />
                <Layer {...countyHighlightShadow} filter={countyFilter} />
                <Layer {...zipIdentify} />
                <Layer {...zipHighlightOutline} filter={identifyFilter} />
                <Layer {...zipHighlightShadow} filter={zipFilter} />
            </Source>
        </>
    );

    return (
        <ParcelViewerContext.Provider value={context}>
            <Map
                ref={mapRef}
                mapboxAccessToken={MAPBOX_TOKEN}
                mapStyle={mapStyle}
                minZoom={4}
                maxZoom={20}
                onClick={onClick}
                interactiveLayerIds={["parcel_identify"]}
                initialViewState={initialViewState}
                hash="view"
                onError={onError}
            >
                {sourcesAndLayers}

                <MapHeader
                    setActiveParcelID={setActiveParcelID}
                    setSearchResult={setSearchResult}
                    setShowFilterPanel={(isOpen) => {
                        setShowFilterPanel(isOpen);
                        setActiveParcelID(null);
                    }}
                />

                {showFilterPanel && (
                    <FilterPanel
                        setOpen={setShowFilterPanel}
                        searchResult={searchResult}
                        setSearchResult={setSearchResult}
                    />
                )}

                {activeParcelID && (
                    <ParcelDetail
                        parcelID={activeParcelID}
                        onClose={() => setActiveParcelID(null)}
                    />
                )}

                <DataControl
                    showCountyLayer={showCountyLayer}
                    setShowCountyLayer={setShowCountyLayer}
                    showZipLayer={showZipLayer}
                    setShowZipLayer={setShowZipLayer}
                    showDebugBoundaries={showDebugBoundaries}
                    setShowDebugBoundaries={setShowDebugBoundaries}
                    showWetlandsLayer={showWetlandsLayer}
                    setShowWetlandsLayer={setShowWetlandsLayer}
                    showBuildingsLayer={showBuildingsLayer}
                    setShowBuildingsLayer={setShowBuildingsLayer}
                    showRoadsLayer={showRoadsLayer}
                    setShowRoadsLayer={setShowRoadsLayer}
                />
                <LayerControl setMapStyle={setMapStyle} />

                <ToolsControl />

                <SavedListsControl open={showSavedLists} setOpen={setShowSavedLists} />

                <HelpControl />
                <NavigationControl position="top-right" />
                <InspectControl position="bottom-right" />
                <FullscreenControl position="bottom-right" />

                {styleLoaded && <DrawControl position={"bottom-right"} />}
            </Map>
        </ParcelViewerContext.Provider>
    );
}

function getBBox(parcel: Parcel): LngLatBounds {
    const lng0 = parcel.bbox ? parcel.bbox[0] : parcel.point[0];
    const lat0 = parcel.bbox ? parcel.bbox[1] : parcel.point[1];
    const lng1 = parcel.bbox ? parcel.bbox[2] : parcel.point[0];
    const lat1 = parcel.bbox ? parcel.bbox[3] : parcel.point[1];
    return new LngLatBounds([lng0, lat0], [lng1, lat1]);
}

function calculateBounds(parcels: Parcel[]): LngLatBounds {
    if (!parcels || parcels.length === 0) {
        return null;
    }
    const bounds = new LngLatBounds();
    for (const parcel of parcels) {
        if (parcel?.point) {
            bounds.extend(getBBox(parcel));
        }
    }
    return bounds;
}
