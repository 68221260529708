import React, { forwardRef, useRef, useImperativeHandle, useEffect } from "react";

import type { TypeaheadRef, TypeaheadMenuProps } from "react-bootstrap-typeahead";

import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

import type { Option, SearchOption } from "../types";

// UseAsyncProps includes the props required by the AsyncTypeahead component.
// These props are shadowed and declared optional mainly to satisfy TypeScript.
// Ref: https://github.com/ericgio/react-bootstrap-typeahead/blob/319d2f5e97daf7857139d4b72a56a3457751576c/src/behaviors/async.tsx
interface UseAsyncProps {
    autoFocus?: boolean;
    className?: string;
    defaultInputValue?: any;
    disabled?: boolean;
    emptyLabel?: string;
    inputProps?: Record<string, any>;
    isInvalid?: boolean;
    isLoading?: boolean;
    onChange?: (selected: Option[]) => void;
    onInputChange?: (text: string) => void;
    onSearch?: (text: string) => void;
    options?: any[];
    minLength?: number;
    delay?: number;
    placeholder?: string;
    promptText?: string;
    style?: any;
}

// LIAsyncTypeaheadProps includes all the props required by the
// LIAsyncTypeahead component. All components which wrap LIAsyncTypeaheadProps
// should extend from this interface.
export interface LIAsyncTypeaheadProps extends UseAsyncProps {
    onClear?: () => void;
}

// LIAsyncTypeahead wraps AsyncTypeahead to provide sensible defaults.
export const LIAsyncTypeahead = forwardRef(function LIAsyncTypeahead(
    {
        inputProps,
        onClear,
        autoFocus,
        isLoading,
        onSearch,
        options,
        ...props
    }: LIAsyncTypeaheadProps,
    ref,
) {
    const typeaheadRef = useRef(null);

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    // Change input type to "search" to disable autocomplete in Chrome
    inputProps = { type: "search", autoComplete: "off", ...inputProps };

    // Expose a ref to control the underlying AsyncTypeahead component
    useImperativeHandle(ref, () => typeaheadRef.current, []);

    // Trigger onClear event handler when input field is cleared
    const onInputChange = (value: string) => {
        if (onClear && value.length === 0) {
            onClear();
        }
    };

    // Focus input field on mount if `autoFocus` is true.
    // react-bootstrap-typeahead has an `autoFocus` prop already but it doesn't
    // really work.
    useEffect(() => {
        if (typeaheadRef.current && autoFocus) {
            typeaheadRef.current.focus();
        }
    }, [typeaheadRef, autoFocus]);

    return (
        <AsyncTypeahead
            ref={typeaheadRef}
            id="typeahead"
            labelKey="label"
            minLength={3}
            filterBy={filterBy}
            emptyLabel="No matches found"
            renderMenuItemChildren={renderMenuItemChildren}
            useCache={false}
            highlightOnlyResult
            inputProps={inputProps}
            onInputChange={onInputChange}
            isLoading={isLoading}
            onSearch={onSearch}
            options={options}
            {...props}
        />
    );
});

function renderMenuItemChildren(option: Option, { text }: TypeaheadMenuProps) {
    const { label, subLabel } = option as SearchOption;
    return (
        <>
            <Highlighter search={text}>{label}</Highlighter>
            {subLabel && (
                <div>
                    <small>{subLabel}</small>
                </div>
            )}
        </>
    );
}

// setAsyncTypeaheadValue sets the input value of the typeahead field. This
// uses the internal setState method as a workaround because this functionality
// is not officially supported.
// Ref: https://github.com/ericgio/react-bootstrap-typeahead/issues/266
export function setAsyncTypeaheadValue(ref: TypeaheadRef, text: string) {
    ref.setState({ text });
}
