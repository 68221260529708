import React from "react";

import type { User, County, MapFilter, SavedList } from "./types";

interface ParcelViewerContextType {
    setMapFilter: (value: MapFilter) => void;
    county: County;
    setCounty: (value: County) => void;
    savedList: SavedList;
    setSavedList: (value: SavedList) => void;
}

export const ParcelViewerContext = React.createContext<ParcelViewerContextType | null>(
    null,
);

interface UserContextType {
    user: User;
}

export const UserContext = React.createContext<UserContextType | null>(null);
