import React, { useContext, useState, useEffect, useCallback } from "react";

import Modal from "react-bootstrap/Modal";

import { toast } from "react-toastify";

import { UserContext } from "context";
import { API_URL, IS_PROD } from "settings";
import { CREDIT_TYPE_LAND_SCRUBBING, CREDIT_TYPE_SKIP_TRACING } from "constants";
import {
    fetch,
    formatCredits,
    formatDate,
    formatMoneyCents,
    parseErrorResponse,
    post,
    prettify_string,
} from "functions";
import { AGGrid, PurchaseCreditsModal } from "components";

export default function Settings() {
    return (
        <div className="row">
            <div className="col-lg-9 mx-auto">
                <Wallet />
                <ManageSubscriptions />
                <BasicInfo />
                <ChangePassword />
            </div>
        </div>
    );
}

function BasicInfo() {
    const { user, setUser } = useContext(UserContext);
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);

    const updateProfile = async () => {
        if (firstName === user.first_name && lastName === user.last_name) {
            return;
        }
        try {
            const user = await post("/user/user/", {
                first_name: firstName,
                last_name: lastName,
            });
            setUser(user);
            toast.success("Profile saved");
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    const updateEmail = async () => {
        if (email === user.email) {
            return;
        }
        try {
            const user = await post("/user/user/change_email/", {
                new_email: email,
            });
            setUser(user);
            toast.success("Email updated");
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    const save = () => {
        updateProfile();
        updateEmail();
    };

    return (
        <div className="card mt-4">
            <div className="card-header pb-0">
                <h5>Basic Info</h5>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-6">
                        <label className="form-label" htmlFor="firstName">
                            First Name
                        </label>
                        <div className="input-group">
                            <input
                                id="firstName"
                                name="firstName"
                                className="form-control"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <label className="form-label" htmlFor="lastName">
                            Last Name
                        </label>
                        <div className="input-group">
                            <input
                                id="lastName"
                                name="lastName"
                                className="form-control"
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <label className="form-label mt-4" htmlFor="email">
                            Email
                        </label>
                        <div className="input-group">
                            <input
                                id="email"
                                name="email"
                                className="form-control"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <button
                    className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
                    onClick={save}
                >
                    Save
                </button>
            </div>
        </div>
    );
}

function ChangePassword() {
    const { user } = useContext(UserContext);
    const [oldPassword, setOldPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();

    const changePassword = async () => {
        try {
            await post("/user/user/change_password/", {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_new_password: confirmNewPassword,
            });
            toast.success("Password changed");
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    return (
        <div className="card mt-4">
            <div className="card-header pb-0">
                <h5>Change Password</h5>
            </div>
            <div className="card-body">
                <label className="form-label" htmlFor="password">
                    Current password
                </label>
                <div className="form-group">
                    <input
                        id="password"
                        name="password"
                        className="form-control"
                        type="password"
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                </div>
                <label className="form-label" htmlFor="newPassword">
                    New password
                </label>
                <div className="form-group">
                    <input
                        id="newPassword"
                        name="newPassword"
                        className="form-control"
                        type="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </div>
                <label className="form-label" htmlFor="confirmPassword">
                    Confirm new password
                </label>
                <div className="form-group">
                    <input
                        id="confirmPassword"
                        name="confirmPassword"
                        className="form-control"
                        type="password"
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                    />
                </div>
                <button
                    className="btn bg-gradient-dark btn-sm float-end mt-4 mb-0"
                    onClick={changePassword}
                >
                    Update password
                </button>
            </div>
        </div>
    );
}

function Wallet() {
    const { transactions } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);

    const columns = [
        {
            headerName: "Created",
            field: "created_at",
            valueFormatter: ({ value }) => formatDate(value),
            filter: true,
            cellDataType: "dateString",
        },
        {
            headerName: "Credit",
            field: "credit",
            valueFormatter: ({ value }) => (value && formatCredits(value)) || "",
        },
        {
            headerName: "Debit",
            field: "debit",
            valueFormatter: ({ value }) => (value && formatCredits(value)) || "",
        },
        {
            headerName: "Paid",
            field: "paid_amount",
            valueFormatter: ({ value }) =>
                value != null ? formatMoneyCents(value) : "",
        },
        { headerName: "Description", field: "description" },
    ];

    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5>Credits</h5>
            </div>
            <div className="card-body">
                {(showModal && (
                    <PurchaseCreditsModal
                        onConfirmed={() => setShowModal(false)}
                        onDismissed={() => setShowModal(false)}
                    />
                )) ||
                    null}
                {transactions.length === 0 ? (
                    <div className="text-center">
                        <button
                            className="btn btn-lg btn-primary"
                            onClick={() => setShowModal(true)}
                        >
                            Purchase Credits
                        </button>
                    </div>
                ) : (
                    <AGGrid fill_table={true} rows={transactions} columns={columns} />
                )}
            </div>
        </div>
    );
}

function ManageSubscriptions() {
    const [subscriptions, setSubscriptions] = useState();

    // Fetch Stripe subscriptions on page load
    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const subscriptions = await fetch("/user/subscriptions/");
                setSubscriptions(subscriptions.subscriptions);
            } catch (xhr) {
                toast.error(parseErrorResponse(xhr));
            }
        };
        fetchSubscriptions();
    }, []);

    const loading = subscriptions === undefined;
    const dataSubs = subscriptions?.filter(
        (sub) => sub.product_metadata.unlimited_data,
    );
    const mainSubs = subscriptions?.filter(
        (sub) => !sub.product_metadata.unlimited_data,
    );

    return (
        <>
            {!IS_PROD && (
                <DataPlatformSubscription loading={loading} subscriptions={dataSubs} />
            )}
            <LandInsightsSubscription loading={loading} subscriptions={mainSubs} />
        </>
    );
}

function DataPlatformSubscription({ loading, subscriptions }) {
    return (
        <div className="card mt-4">
            <div className="card-header pb-0">
                <h5>Data Platform Subscription</h5>
            </div>
            <div className="card-body">
                {loading ? (
                    "Loading..."
                ) : subscriptions.length ? (
                    <ManageSubscriptionButton subscriptions={subscriptions} />
                ) : (
                    <UpgradeSubscriptionButton />
                )}
            </div>
        </div>
    );
}

function LandInsightsSubscription({ loading, subscriptions }) {
    return (
        <div className="card mt-4">
            <div className="card-header pb-0">
                <h5>Land Insights Subscription</h5>
            </div>
            <div className="card-body">
                {loading ? (
                    "Loading..."
                ) : (
                    <ManageSubscriptionButton subscriptions={subscriptions} />
                )}
            </div>
        </div>
    );
}

function UpgradeSubscriptionButton() {
    const onClick = async () => {
        try {
            const result = await post(`${API_URL}/user/subscribe_unlimited/`);
            window.open(result.stripe_url, "_blank").focus();
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    return (
        <>
            <p>
                <strong>Current plan:</strong> A-La-Carte Data at 6 cents per record.
            </p>
            <div className="d-flex justify-content-center">
                <button className="btn bg-gradient-success btn-lg" onClick={onClick}>
                    Update To Unlimited Data
                </button>
            </div>
        </>
    );
}

function ManageSubscriptionButton({ subscriptions }) {
    const [selectedCustomerID, setSelectedCustomerID] = useState();

    const groupedSubs = groupByCustomer(subscriptions);

    return (
        <div className="d-flex gap-2 justify-content-center">
            {selectedCustomerID && (
                <SubscriptionOptionsModal
                    customerID={selectedCustomerID}
                    onHide={() => setSelectedCustomerID(null)}
                />
            )}
            {Object.entries(groupedSubs).map(([customer_id, subs]) => (
                <button
                    key={customer_id}
                    className="btn bg-gradient-secondary btn-lg"
                    onClick={() => setSelectedCustomerID(customer_id)}
                >
                    Open My Subscription Portal
                    <div className="text-xs text-light pt-2">
                        Manage{" "}
                        <strong>
                            {subs.map((sub) => sub.product_name).join(", ")}
                        </strong>
                    </div>
                </button>
            ))}
        </div>
    );
}

function SubscriptionOptionsModal({ customerID, onHide }) {
    const calendlyUrl =
        "https://calendly.com/hello-6kuo/land-insights-exit-call-with-ari-owens";

    const onClick = async () => {
        try {
            const result = await post(`${API_URL}/user/billing_portal/${customerID}/`);
            window.open(result.stripe_url, "_blank").focus();
        } catch (xhr) {
            toast.error(parseErrorResponse(xhr));
        }
    };

    return (
        <Modal
            show
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHide}
        >
            <Modal.Body>
                <h4 className="text-center p-4">What would you like to do?</h4>
                <div className="d-flex gap-2 justify-content-center">
                    <button className="btn bg-gradient-info btn-lg" onClick={onClick}>
                        Update Card
                    </button>
                    <a
                        className="btn bg-gradient-danger btn-lg"
                        href={calendlyUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Cancel Account
                    </a>
                </div>
            </Modal.Body>
        </Modal>
    );
}

function groupByCustomer(subs) {
    const grouped = {};
    for (const sub of subs) {
        const k = sub.customer_id;
        if (!grouped[k]) {
            grouped[k] = [];
        }
        grouped[k].push(sub);
    }
    return grouped;
}
