import { createElement } from "react";
import { getColorClassName, LuiColors } from "../color/color";
import { clsx } from "clsx";

import "./typography.css";

export type TypographyProps = {
    id?: string;
    children: React.ReactNode;
    weight?: "regular" | "medium" | "bold";
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl";
    color?: LuiColors;
    className?: string;
    variant?:
        | "div"

        // Add text tags
        | "p"
        | "span"
        | "i"
        | "b"

        // Add form tags
        | "label"

        // Add heading tags
        | "h1"
        | "h2"
        | "h3"
        | "h4"
        | "h5"
        | "h6";
};

/**
 * Typography component
 *  - Renders text with specified variant, size, weight and color
 * @example
 * <Typography variant="h1" weight="bold" size="4xl" color="gray-700">
 *    Heading 1
 * </Typography>
 */
export function Typography({
    id,
    className,
    children,
    variant = "div",
    size = "md",
    weight,
    color = "gray-1000", // Default color is gray-1000
}: TypographyProps) {
    return createElement(
        variant,
        {
            id,
            className: clsx(
                className,
                "lui-typography",
                `lui-typography-${size}`,
                `lui-typography-${weight}`,
                getColorClassName(color),
            ),
        },
        children,
    );
}
