import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { ACRE_RANGES, RANGE_PAIRS } from "../../../constants";
import { fetch, parseErrorResponse, formatNumber } from "functions";
import type { Region, RegionStatistics, FullParcel } from "../types";
import { Typography } from "../../../land_ui/typography/typography";
import { Tabs } from "../../../land_ui/tabs/tabs";
import { get, isNumber, lowerCase, toNumber, toString } from "lodash";
import { Badge } from "../../../land_ui/badge/badge";
import { formatDate } from "../../../functions/date_utils";
import dayjs from "dayjs";
import { CopyButton } from "../../../land_ui/button/copy_button";

const TARGET_RANGES = [
    "1 acre-2 acre",
    "2 acre-5 acre",
    "5 acre-10 acre",
    "10 acre-20 acre",
    "20 acre-40 acre",
    "40 acre-100 acre",
];

type RangePairTuple = [number, number, string]; // minAcre, maxAcre, key

// Retrieve min/max acres for each of our target ranges from RANGE_PAIRS
const TARGET_RANGE_PAIRS: RangePairTuple[] = (RANGE_PAIRS as RangePairTuple[]).filter(
    ([, , key]: RangePairTuple) => TARGET_RANGES.includes(key),
);

const PLACEHOLDER_LABEL = "——";

interface ParcelDetailProps {
    parcelID: number;
    onClose: () => void;
}

export default function ParcelDetail({ parcelID, onClose }: ParcelDetailProps) {
    const [parcel, setParcel] = useState<FullParcel>();
    const [county, setCounty] = useState<Region>();
    const [countyError, setCountyError] = useState("");
    const parcelAcres = getDecimalValue(get(parcel, "LotSizeAcres"), 3);

    useEffect(() => {
        const fetchParcel = async (id: number) => {
            try {
                const parcel: FullParcel = await fetch(`/api/property/parcels/${id}/`);
                setParcel(parcel);
            } catch (xhr) {
                const errorMessage =
                    (xhr as any).status === 404
                        ? `No data available for parcel ${id}`
                        : parseErrorResponse(xhr);
                Swal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        };

        fetchParcel(parcelID);
    }, [parcelID]);

    useEffect(() => {
        if (!parcel) {
            return;
        }

        const fetchCounty = async (fips: string) => {
            try {
                const county: Region = await fetch(`/api/county_metrics/${fips}/`);
                setCounty(county);
            } catch (xhr) {
                setCountyError("Error loading county metrics.");
                console.error("Error loading county", xhr);
            }
        };

        fetchCounty(parcel.FIPS);
    }, [parcel]);

    return (
        <div className="lui-py-6 lui-h-[85vh] lui-ring-8 ring-inset lui-absolute lui-top-24 lui-left-8 lui-bg-white lui-w-2/5 lui-rounded-xl lui-max-w-[475px]  lui-flex lui-flex-col  ">
            <div className="lui-px-5  lui-flex lui-justify-between">
                <div className="lui-flex lui-flex-col lui-gap-4 lui-items-center">
                    <Typography
                        size="sm"
                        color="gray-700"
                        className="lui-group hover:lui-bg-gray-100  lui-p-1 lui-rounded-md lui-flex lui-gap-2 lui-justify-between"
                    >
                        #{getValueOrPlaceholder(parcel, "APN")}
                        <CopyButton
                            size="sm"
                            text={getValueOrPlaceholder(parcel, "APN")}
                            className="lui-hidden group-hover:lui-block lui-p-0"
                        />
                    </Typography>
                </div>
                <CloseButton onClick={onClose} />
            </div>

            <div className="lui-px-6  lui-flex lui-justify-between">
                <div className="lui-flex lui-flex-col gap-1">
                    <Typography size="xl" color="gray-1000" weight="bold">
                        {getValueOrPlaceholder(county, "name")},{" "}
                        {getValueOrPlaceholder(county, "state")}
                    </Typography>
                    <Typography color="primary-500" weight="bold">
                        {parcelAcres} acres
                    </Typography>
                </div>
            </div>

            <div className="lui-mt-4 lui-px-6">
                <Typography size="sm" weight="medium" className="lui-capitalize">
                    {getOwnerFullName(parcel)}
                </Typography>
                <Typography
                    size="sm"
                    weight="medium"
                    color="gray-700"
                    className="lui-mt-0.5"
                >
                    <span>
                        {getValueOrPlaceholder(parcel, "MailingZIP5")}-
                        {getValueOrPlaceholder(parcel, "MailingZIP4")}
                    </span>
                </Typography>
            </div>

            <NewParcelAcresStats
                parcel={parcel}
                county={county}
                countyError={countyError}
            />

            <div className="lui-mt-1 lui-overflow-y-scroll">
                <Tabs
                    // Use key to force re-render when parcel changes, this will reset the active tab
                    key={get(parcel, "PropertyID")}
                    options={[
                        {
                            title: "Summary",
                            content: (
                                <TabParcelSummaryDetails
                                    parcel={parcel}
                                    county={county}
                                />
                            ),
                        },
                        {
                            title: "Parcel",

                            content: (
                                <TabParcelDetails parcel={parcel} county={county} />
                            ),
                        },
                        {
                            title: "Owner",
                            content: <TabOwnerDetails parcel={parcel} />,
                        },
                        {
                            title: "Structure",
                            content: <TabStructureDetails parcel={parcel} />,
                        },
                        {
                            title: "Metrics",
                            content: (
                                <TabsMetricsDetails parcel={parcel} county={county} />
                            ),
                        },
                        {
                            title: "Misc",
                            content: <TabsMiscDetails parcel={parcel} />,
                        },
                    ]}
                />
            </div>
        </div>
    );
}

interface ParcelDetailBodyProps {
    parcel: FullParcel;
    county: Region;
    countyError: string;
}

function TabsMetricsDetails({
    parcel,
    county,
}: {
    parcel: FullParcel;
    county: Region;
}) {
    const metricsDetails = getParcelMetrics(parcel, county);
    return (
        <div>
            <div className="lui-grid lui-gap-4 lui-grid-cols-2">
                <div className="lui-col-span-2">
                    <Typography weight="bold">{county?.name} Metrics</Typography>
                    <Typography size="sm" color="gray-700">
                        {getParcelAcres(parcel)}
                    </Typography>
                </div>
            </div>
            <div className="lui-grid lui-gap-4 lui-grid-cols-4 lui-mt-6">
                <ParcelSummaryDetailsCell
                    title="Active"
                    content={metricsDetails.ACTIVE}
                />
                <ParcelSummaryDetailsCell
                    title="Pending"
                    content={metricsDetails.PENDING}
                />
                <div className="lui-col-span-2">
                    <ParcelSummaryDetailsCell
                        title="1yr STR"
                        content={metricsDetails.STR_ONE_YEAR}
                    />
                </div>
                <ParcelSummaryDetailsCell
                    title="Sold 1mo"
                    content={metricsDetails.SOLD_1_MONTH}
                />
                <ParcelSummaryDetailsCell
                    title="Sold 3mo"
                    content={metricsDetails.SOLD_3_MONTHS}
                />
                <ParcelSummaryDetailsCell
                    title="Sold 6mo"
                    content={metricsDetails.SOLD_6_MONTHS}
                />
                <ParcelSummaryDetailsCell
                    title="Sold 1yr"
                    content={metricsDetails.SOLD_1_YEAR}
                />
            </div>
        </div>
    );
}

function TabsMiscDetails({ parcel }: { parcel: FullParcel }) {
    return (
        <div className="lui-grid lui-gap-4 lui-grid-cols-2">
            <ParcelSummaryDetailsCell
                title="Mortgage Amount"
                content={formatCurrency(get(parcel, "ConcurrentMtg1LoanAmt"))}
            />
            <ParcelSummaryDetailsCell
                title="Mortgage Length"
                content={getTermsLabel(get(parcel, "ConcurrentMtg1Term"))}
            />
            <ParcelSummaryDetailsCell
                title="Mortgage Lender"
                content={getValueOrPlaceholder(parcel, "ConcurrentMtg1Lender")}
            />

            <ParcelSummaryDetailsCell
                title="Mortgage Matures In"
                content={formatDate(
                    dayjs(get(parcel, "ConcurrentMtg1RecordingDate")).add(
                        toNumber(get(parcel, "ConcurrentMtg1Term")),
                        "months",
                    ),
                    "RELATIVE",
                )}
            />
            <ParcelSummaryDetailsCell
                title="Mortgage Type"
                content={getValueOrPlaceholder(parcel, "ConcurrentMtg1TypeFinancing")}
            />
            <ParcelSummaryDetailsCell
                title="Loan Type"
                content={getValueOrPlaceholder(parcel, "ConcurrentMtg1LoanType")}
            />
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Mortgage Interest"
                    content={getInterestRate(parcel?.ConcurrentMtg1InterestRate)}
                />
            </div>
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="School District"
                    content={getValueOrPlaceholder(parcel, "SchoolDistrictName")}
                />
            </div>
        </div>
    );
}

function NewParcelAcresStats({ parcel, county, countyError }: ParcelDetailBodyProps) {
    const acresSizeLabel = getParcelAcres(parcel);
    const metricsDetails = getParcelMetrics(parcel, county);

    return (
        <div className="lui-mt-4  lui-grid lui-grid-cols-2 lui-justify-between lui-border-solid lui-border-gray-100">
            <div className="lui-px-6 lui-py-3 lui-border-y-0 lui-border-r lui-border-solid lui-border-gray-100">
                <Typography color="gray-700" size="sm">
                    {acresSizeLabel} 1 Yr STR
                </Typography>
                <Typography weight="medium" size="sm" className="lui-mt-2">
                    {metricsDetails.STR_ONE_YEAR}
                </Typography>
            </div>

            <div className="lui-px-6 lui-py-3">
                <Typography color="gray-700" size="sm">
                    {acresSizeLabel} Total Sold 1 Yr
                </Typography>
                <Typography weight="medium" size="sm" className="lui-mt-2">
                    {metricsDetails.SOLD_1_YEAR}
                </Typography>
            </div>
        </div>
    );
}

function getParcelMetrics(parcel: FullParcel, county: Region) {
    if (!parcel || !county) {
        return {
            ACTIVE: PLACEHOLDER_LABEL,
            PENDING: PLACEHOLDER_LABEL,
            STR_ONE_YEAR: PLACEHOLDER_LABEL,
            SOLD_1_MONTH: PLACEHOLDER_LABEL,
            SOLD_3_MONTHS: PLACEHOLDER_LABEL,
            SOLD_6_MONTHS: PLACEHOLDER_LABEL,
            SOLD_1_YEAR: PLACEHOLDER_LABEL,
        };
    }

    const lotSize = getDecimalValue(parcel?.LotSizeAcres, 3);

    const range = TARGET_RANGE_PAIRS.find(
        ([minAcre, maxAcre, _]) => lotSize >= minAcre && lotSize < maxAcre,
    ) || [0, 0, ""];

    const [, , acreageKey] = range;
    const metrics = county.stats.find(
        (stat: RegionStatistics) => stat.type === acreageKey,
    );

    return {
        ACTIVE: metrics?.data["Active"] ?? PLACEHOLDER_LABEL,
        PENDING: metrics?.data["Pending"] ?? PLACEHOLDER_LABEL,
        STR_ONE_YEAR: metrics?.data["1yr STR"]
            ? `${metrics?.data["1yr STR"]}%`
            : PLACEHOLDER_LABEL,
        SOLD_1_MONTH: metrics?.data["Sold: 1mo"] ?? PLACEHOLDER_LABEL,
        SOLD_3_MONTHS: metrics?.data["Sold: 3mo"] ?? PLACEHOLDER_LABEL,
        SOLD_6_MONTHS: metrics?.data["Sold: 6mo"] ?? PLACEHOLDER_LABEL,
        SOLD_1_YEAR: metrics?.data["Sold: 1yr"] ?? PLACEHOLDER_LABEL,
    };
}

function CloseButton({ onClick }: { onClick: () => void }) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="lui-cursor-pointer"
            onClick={onClick}
        >
            <path
                d="M18 6L6 18M6 6L18 18"
                stroke="#171717"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}

function TabParcelSummaryDetails({
    parcel,
    county,
}: {
    parcel: FullParcel;
    county: Region;
}) {
    return (
        <div className="lui-grid lui-gap-4 lui-grid-cols-2">
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Owner Mailing Address"
                    content={getParcelMailingAddress(parcel)}
                />
            </div>
            <ParcelSummaryDetailsCell
                title="County"
                content={get(county, "name", PLACEHOLDER_LABEL)}
            />
            <ParcelSummaryDetailsCell
                title="Acres"
                content={getDecimalValue(get(parcel, "LotSizeAcres"), 3)}
            />
            <ParcelSummaryDetailsCell
                title="Last Purchase Price"
                content={formatCurrency(get(parcel, "CurrentSalesPrice"))}
            />
            <ParcelSummaryDetailsCell
                title="Last Purchase Date"
                content={formatDate(
                    get(parcel, "CurrentSaleRecordingDate"),
                    "RELATIVE",
                )}
            />
            <ParcelSummaryDetailsCell
                title="Deed Type"
                content={getValueOrPlaceholder(parcel, "CurrentSaleDocumentType")}
            />
            <ParcelSummaryDetailsCell
                title="APN"
                content={getValueOrPlaceholder(parcel, "APN")}
            />
            <ParcelSummaryDetailsCell
                title="Zoning"
                content={getValueOrPlaceholder(parcel, "Zoning")}
            />
            <ParcelSummaryDetailsCell
                title="GPS"
                content={`${getValueOrPlaceholder(parcel, "SitusLatitude")}, ${getValueOrPlaceholder(parcel, "SitusLongitude")}`}
            />
            {/* TODO(KM) is this the right value for tax owed? */}
            <ParcelSummaryDetailsCell
                title="Taxes Owed"
                content={formatCurrency(get(parcel, "TaxAmt", 0) / 100)}
            />
            <ParcelSummaryDetailsCell
                title="Tax Delinquent For"
                content={getValueOrPlaceholder(parcel, "TaxDeliquentYear")}
            />
            <ParcelSummaryDetailsCell
                title="In HOA"
                content={parcel?.HOA1Type ? "Yes" : "No"}
            />

            {/* TODO(km) Check inter family */}
            <ParcelSummaryDetailsCell
                title="Family Transfer"
                content={parcel?.InterFamilyFlag ? "Yes" : "No"}
            />
            <ParcelSummaryDetailsCell
                title="Google Maps"
                content={
                    // TODO(KM) Use the Link component
                    parcel ? (
                        <a
                            href={`https://www.google.com/maps/place/${parcel.SitusLatitude},${parcel.SitusLongitude}`}
                            target="_blank"
                            rel="noreferrer"
                            className="lui-underline"
                        >
                            Link
                        </a>
                    ) : (
                        PLACEHOLDER_LABEL
                    )
                }
            />
            <ParcelSummaryDetailsCell
                title="Google Earth"
                content={
                    // TODO(KM) Use the Link component
                    parcel ? (
                        <a
                            href={`https://earth.google.com/web/@${parcel.SitusLatitude},${parcel.SitusLongitude},1000a,35y,0h,0t,0r`}
                            target="_blank"
                            rel="noreferrer"
                            className="lui-underline"
                        >
                            Link
                        </a>
                    ) : (
                        PLACEHOLDER_LABEL
                    )
                }
            />
            <ParcelSummaryDetailsCell
                title="County Assessed Value"
                content={formatCurrency(get(parcel, "AssdTotalValue"))}
            />
            <ParcelSummaryDetailsCell
                title="Calculated Acres"
                content={getDecimalValue(get(parcel, "CalculatedAcres"))}
            />
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Legal Description"
                    content={getValueOrPlaceholder(parcel, "LegalDescription")}
                />
            </div>
        </div>
    );
}

function TabParcelDetails({ parcel, county }: { parcel: FullParcel; county: Region }) {
    if (!parcel) {
        return null;
    }

    return (
        <div className="lui-grid lui-gap-4 lui-grid-cols-2">
            <ParcelSummaryDetailsCell title="County" content={county?.name} />
            <ParcelSummaryDetailsCell
                title="Acres"
                content={getDecimalValue(get(parcel, "LotSizeAcres"), 3)}
            />
            <ParcelSummaryDetailsCell
                title="APN"
                content={getValueOrPlaceholder(parcel, "APN")}
            />

            <ParcelSummaryDetailsCell
                title="Calculated Acres"
                content={getDecimalValue(get(parcel, "CalculatedAcres"))}
            />
            <ParcelSummaryDetailsCell
                title="GPS"
                content={`${getValueOrPlaceholder(parcel, "SitusLatitude")}, ${getValueOrPlaceholder(
                    parcel,
                    "SitusLongitude",
                )}`}
            />
            <ParcelSummaryDetailsCell
                title="Improvement %"
                content={
                    isNumber(parcel?.ImprovementPercentage)
                        ? `${parcel.ImprovementPercentage}%`
                        : PLACEHOLDER_LABEL
                }
            />
            <ParcelSummaryDetailsCell
                title="Zoning"
                content={getValueOrPlaceholder(parcel, "Zoning")}
            />

            <ParcelSummaryDetailsCell
                title="Current Land Use"
                content={getValueOrPlaceholder(parcel, "LandUseCode")}
            />
            <ParcelSummaryDetailsCell
                title="Subdivision"
                content={getValueOrPlaceholder(parcel, "SubdivisionName")}
            />
            <div className="lui-col-span-2">
                <ParcelSummaryDetailsCell
                    title="Legal Description"
                    content={getValueOrPlaceholder(parcel, "LegalDescription")}
                />
            </div>
        </div>
    );
}

function TabOwnerDetails({ parcel }: { parcel: FullParcel }) {
    if (!parcel) {
        return null;
    }

    return (
        <>
            <div className="lui-grid lui-gap-4 lui-grid-cols-2">
                <div className="lui-col-span-2 lui-p-2">
                    <Typography size="sm" weight="medium" className="lui-capitalize">
                        {getOwnerFullName(parcel)}
                    </Typography>
                    <Typography size="sm" color="gray-700" className="lui-mt-0.5">
                        {getParcelMailingAddress(parcel)}
                    </Typography>
                </div>

                <ParcelSummaryDetailsCell
                    title="Out of state"
                    content={parcel.OutOfStateOwner ? "Yes" : "No"}
                />
                <ParcelSummaryDetailsCell
                    title="Out of County"
                    content={parcel.OutOfCountyOwner ? "Yes" : "No"}
                />
                <ParcelSummaryDetailsCell
                    title="Out of ZIP"
                    content={parcel.OutOfZipOwner ? "Yes" : "No"}
                />
                <ParcelSummaryDetailsCell
                    title="Ownership Length"
                    content={formatDate(
                        dayjs().subtract(parcel.OwnershipLength, "months"),
                        "RELATIVE",
                    )}
                />
                <div className="lui-col-span-2">
                    <ParcelSummaryDetailsCell
                        title="Prior Owner Name"
                        content={getValueOrPlaceholder(
                            parcel,
                            "CurrentSaleSeller1FullName",
                        )}
                    />
                </div>
            </div>
            <div className="lui-col-span-2 lui-mt-8">
                <Typography color="black" weight="bold" size="xl">
                    Chain of Ownership
                </Typography>
            </div>
            <div className="lui-mt-3 lui-flex lui-flex-col lui-gap-3">
                <ParcelOwnerDetailsCard
                    date={get(parcel, "CurrentSaleRecordingDate")}
                    isFamilyTransfer={parcel?.InterFamilyFlag}
                    hasMortgage={!!parcel?.ConcurrentMtg1LoanAmt}
                    name={getOwnerFullName(parcel)}
                    amount={get(parcel, "CurrentSalesPrice")}
                />
                <ParcelOwnerDetailsCard
                    date={formatDate(get(parcel, "PrevSaleRecordingDate"))}
                    amount={get(parcel, "PrevSalesPrice")}
                    name={getValueOrPlaceholder(parcel, "CurrentSaleSeller1FullName")}
                />
            </div>
        </>
    );
}

function TabStructureDetails({ parcel }: { parcel: FullParcel }) {
    if (!parcel) {
        return null;
    }

    return (
        <div className="lui-grid lui-gap-4 lui-grid-cols-2">
            <ParcelSummaryDetailsCell
                title="Structures"
                content={`${formatNumber(parcel.SumBuildingSqFt)} sqft`}
            />
            <ParcelSummaryDetailsCell
                title="Structure count"
                content={parcel.SumBuildingsNbr}
            />
            <ParcelSummaryDetailsCell
                title="Structure year built"
                content={getValueOrPlaceholder(parcel, "EffectiveYearBuilt")}
            />
        </div>
    );
}

function getParcelMailingAddress(parcel: FullParcel) {
    const fullAddress = get(parcel, "MailingFullStreetAddress");
    const city = get(parcel, "MailingCity");
    const state = get(parcel, "MailingState");
    const zip4 = get(parcel, "MailingZIP4");
    const zip5 = get(parcel, "MailingZIP5");

    if (!fullAddress) {
        return "Unknown Mailing Address";
    }

    return `${fullAddress}, ${city}, ${state} ${zip4}-
            ${zip5}`;
}

function ParcelSummaryDetailsCell({
    title,
    content,
}: {
    title: React.ReactNode;
    content: React.ReactNode;
}) {
    return (
        <div className="lui-p-2 lui-group hover:lui-bg-gray-100 lui-rounded-md">
            <div className="lui-flex lui-justify-between">
                <div>
                    <Typography size="sm" color="gray-700">
                        {title}
                    </Typography>
                    <Typography size="sm" weight="medium" className="lui-mt-0.5">
                        {content}
                    </Typography>
                </div>
                {!!content && content !== PLACEHOLDER_LABEL && (
                    <CopyButton
                        text={toString(content)}
                        className="lui-hidden group-hover:lui-block "
                    />
                )}
            </div>
        </div>
    );
}

interface ParcelOwnerDetailsCardProps {
    date: string;
    isFamilyTransfer?: boolean;
    hasMortgage?: boolean;
    name: string;
    amount: number;
}

function ParcelOwnerDetailsCard({
    date,
    isFamilyTransfer,
    hasMortgage,
    name,
    amount,
}: ParcelOwnerDetailsCardProps) {
    return (
        <div className="lui-p-4 lui-border-solid lui-border-gray-200 lui-rounded-xl">
            <div className="lui-flex lui-justify-between">
                <Typography color="gray-700" size="sm">
                    {formatDate(date, "LONG_DATE")}
                </Typography>
                <div className="lui-flex lui-gap-2">
                    {isFamilyTransfer && (
                        <Badge variant="warning">Family Transfer</Badge>
                    )}
                    {hasMortgage && <Badge variant="danger">Mortgage</Badge>}
                </div>
            </div>

            <Typography weight="bold" className="lui-mt-1 lui-capitalize">
                {name}
            </Typography>
            <Typography color="primary-500" className="lui-mt-1">
                {amount ? formatCurrency(amount) : PLACEHOLDER_LABEL}
            </Typography>
        </div>
    );
}
function getOwnerFullName(parcel: FullParcel) {
    if (!parcel) {
        return "";
    }

    if (!parcel.Owner1FirstName && !parcel.Owner1LastName) {
        return "Unknown Owner";
    }

    return lowerCase(
        `${parcel?.Owner1FirstName ?? ""} ${parcel?.Owner1MiddleName ?? ""} ${parcel?.Owner1LastName ?? ""}`,
    );
}

function getParcelAcres(parcel: FullParcel) {
    if (!parcel) {
        return PLACEHOLDER_LABEL;
    }

    const lotSize = getDecimalValue(parcel?.LotSizeAcres, 3);
    const range = TARGET_RANGE_PAIRS.find(
        ([minAcre, maxAcre, _]) => lotSize >= minAcre && lotSize < maxAcre,
    ) || [0, 0, ""];

    const [, , acreageKey] = range;

    let acresSizeLabel =
        ACRE_RANGES[acreageKey as keyof typeof ACRE_RANGES] || "Unknown";

    if (acresSizeLabel === "Unknown" && lotSize >= 100) {
        acresSizeLabel = "100+ Acres";
    }

    return acresSizeLabel;
}

function formatCurrency(value: number) {
    if (value === null || value === undefined) {
        return PLACEHOLDER_LABEL;
    }

    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(value);
}

function getInterestRate(rate: number) {
    if (!isNumber(rate)) {
        return PLACEHOLDER_LABEL;
    }
    const rateValue = rate / 100;

    return `${rateValue}%`;
}

function getTermsLabel(term: string) {
    if (!term) {
        return PLACEHOLDER_LABEL;
    }

    const termsNumber = parseInt(term, 10);

    if (termsNumber >= 12) {
        return `${termsNumber / 12} years`;
    }

    return `${termsNumber} months`;
}

/**
 * Converts a value to a decimal string with an implied decimal place.
 *
 * @param {number} [value] - The value to be converted.
 * @param {number} [impliedDecimal=2] - The number of implied decimal places.
 * @returns {string|number} The value as a decimal string or a placeholder label if the value is not provided.
 */
function getDecimalValue(value?: number, impliedDecimal = 0) {
    if (!value) {
        return 0;
    }

    const result = (value / Math.pow(10, impliedDecimal)).toFixed(1);
    return parseFloat(result) % 1 === 0 ? parseInt(result, 10) : toNumber(result);
}

function getValueOrPlaceholder(obj: unknown, key: string): string {
    const value = get(obj, key);

    if (isNumber(value)) {
        return toString(value);
    }

    if (!!value) {
        return value;
    }

    return PLACEHOLDER_LABEL;
}
