import { Icon } from "../icon/icon";
import { useCombobox } from "downshift";
import clsx from "clsx";
import { Typography } from "../typography/typography";
import { forwardRef, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";
import { useDebouncedCallback } from "use-debounce";
import { ForwardedRef, Ref, RefAttributes, ReactNode } from "react";

export interface AutoCompleteOption<T extends any> {
    id: string;
    label: string;
    // We use this value to display inside the autocomplete input after selection, its not required
    inputValue?: string;
    // For any additional data for easier access
    data?: T;
}
interface AutoCompleteProps<T> {
    id: string;
    setOptions: (options: AutoCompleteOption<T>[]) => void;
    options: AutoCompleteOption<T>[];
    searchValue: string;
    onChange: (inputValue: string) => void;
    selectedOption: AutoCompleteOption<T> | null;
    onSearch: (inputValue: string) => void;
    onSelect?: (selectedItem: AutoCompleteOption<T>) => void;
    placeholder?: string;
    hideBorder?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    renderLabel?: (item: AutoCompleteOption<T>) => ReactNode;
    onReset?: () => void;
    autoFocus?: boolean;
}

const AutocompleteComponent = <T,>(
    {
        id,
        options,
        onSearch,
        onSelect,
        placeholder = "Search",
        hideBorder = false,
        isLoading = false,
        renderLabel,
        disabled,
        searchValue,
        selectedOption,
        onChange,
        onReset,
        autoFocus,
    }: AutoCompleteProps<T>,
    ref: ForwardedRef<HTMLInputElement>,
) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0 } = useResizeObserver({
        ref: containerRef,
        box: "border-box",
    });

    const {
        isOpen,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        reset,
    } = useCombobox({
        inputValue: searchValue,
        items: options || [],

        onSelectedItemChange({ selectedItem }) {
            if (selectedItem) {
                onSelect(selectedItem);
                onChange(selectedItem.inputValue || selectedItem.label);
            } else {
                onSelect(null);
            }
        },
        itemToString(item) {
            return item ? item.label : "";
        },
    });

    return (
        <div
            ref={containerRef}
            className={clsx(
                "lui-bg-white lui-w-full lui-h-full lui-overflow-hidden lui-m-0 lui-p-0",
                {
                    "lui-border lui-border-solid lui-border-gray-300 lui-rounded-3xl":
                        !hideBorder,
                    // Remove rounded bottom left corner when dropdown is open
                    "lui-rounded-b-none": isOpen && !hideBorder,
                },
            )}
        >
            <div className="lui-flex lui-items-center lui-justify-between lui-transition-all lui-duration-1000 lui-gap-1 lui-w-full lui-h-full">
                <input
                    {...getInputProps({
                        ref,
                        // Custom onChange to prevent the cursor movement error https://github.com/downshift-js/downshift/issues/1108
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e.target.value);
                            onSearch(e.target.value);
                        },
                    })}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    className={clsx(
                        "lui-text-sm lui-font lui-flex-grow lui-font-inter lui-h-full lui-border-0 lui-px-3 lui-pl-4 focus-visible:lui-outline-none disabled:lui-cursor-not-allowed",
                        // py is 15.5px because the total of the input height should be 48px
                        "lui-py-[15.5px]",
                        {
                            "lui-font-medium lui-text-gray-1000":
                                !!selectedOption || !!searchValue,
                            "lui-font-normal lui-text-gray-700":
                                !selectedOption && !searchValue,
                        },
                    )}
                    autoComplete="off"
                    disabled={disabled}
                />

                {!!selectedOption && (
                    <span
                        className="lui-text-gray-100 lui-cursor-pointer lui-px-3 lui-py-2.5 lui-flex lui-items-center lui-justify-center"
                        onClick={() => {
                            reset();
                            onReset();
                        }}
                    >
                        <Icon name="Close" size="sm" color="gray-600" />
                    </span>
                )}
            </div>
            <ul
                className={clsx(
                    `lui-absolute lui-z-10 lui-bg-white  lui-max-h-80 lui-overflow-auto lui-p-0  lui-py-2 `,
                    "lui-rounded-b-3xl",
                    "lui-border lui-border-solid",
                    {
                        "lui-hidden": !isOpen,
                        "lui-border-white": !isOpen,
                        "lui-border-solid lui-border-t lui-border-gray-200 ": isOpen,

                        [`lui-autocomplete-${id}--open`]: isOpen, // This is used for to style parent component
                    },
                )}
                style={{
                    width: `${Math.floor(width)}px`,
                }}
                {...getMenuProps()}
            >
                {isOpen &&
                    !isLoading &&
                    options.map((item, index) => (
                        <li
                            key={item.id}
                            className={clsx(
                                "lui-cursor-pointer",
                                highlightedIndex === index && "lui-bg-gray-100",
                                selectedOption?.id === item?.id && "lui-bg-primary-50",
                                selectedOption?.id === item?.id && "lui-font-bold",
                                "lui-py-2.5 lui-px-4 lui-flex lui-gap-4  ",
                            )}
                            {...getItemProps({ item, index })}
                        >
                            {renderLabel && renderLabel(item)}
                            {!renderLabel && (
                                <Typography variant="span" weight="medium">
                                    {item.label}
                                </Typography>
                            )}
                        </li>
                    ))}

                {isOpen && !isLoading && options.length === 0 && (
                    <li className="lui-py-2 lui-px-5 lui-flex lui-gap-4  ">
                        <Typography variant="span">No results found</Typography>
                    </li>
                )}

                {isLoading && (
                    <li className="lui-py-2 lui-px-5 lui-flex lui-gap-4 lui-items-center">
                        <Typography variant="span">Loading ...</Typography>
                    </li>
                )}
            </ul>
        </div>
    );
};

// Export the component with forwardRef
// This is to fix the generic type issue with forwardRef, see more here
// https://www.totaltypescript.com/forwardref-with-generic-components
function fixedForwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactNode,
): (props: P & RefAttributes<T>) => ReactNode {
    // @ts-ignore
    return forwardRef(render) as any;
}

export const Autocomplete = fixedForwardRef(AutocompleteComponent);

interface useAutocompleteProps<T>
    extends Omit<
        AutoCompleteProps<T>,
        "setOptions" | "options" | "searchValue" | "onChange" | "selectedOption"
    > {
    initialOptions?: AutoCompleteOption<T>[];
    onSearch: (inputValue: string) => Promise<AutoCompleteOption<T>[]>;
    onSelect?: (selectedItem: AutoCompleteOption<T>) => void;
}
interface useAutocompleteReturnProps<T> extends AutoCompleteProps<T> {
    ref: React.RefObject<HTMLInputElement>;
}
export function useAutocomplete<T>({
    initialOptions,
    ...props
}: useAutocompleteProps<T>): useAutocompleteReturnProps<T> {
    const ref = useRef<HTMLInputElement>(null);
    const [id] = useState<string>(() => {
        return props.id || Math.random().toString(36).substring(7);
    });
    const [options, setOptions] = useState<AutoCompleteOption<T>[]>(
        initialOptions || [],
    );
    const [selectedOption, onSelectOption] = useState<AutoCompleteOption<T>>();
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, onChange] = useState("");
    const onSearch = useDebouncedCallback(async (inputValue: string) => {
        setIsLoading(true);
        try {
            const list = await props.onSearch(inputValue);
            setOptions(list);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }, 500);

    const onSelect = (selectedItem: AutoCompleteOption<T>) => {
        onSelectOption(selectedItem);
        if (props.onSelect) {
            props.onSelect(selectedItem);
        }
    };

    const onReset = () => {
        setOptions([]);
        onSelect(null);
        onChange("");
        ref.current?.focus();
    };

    return {
        ...props,
        id,
        ref,
        onChange,
        searchValue,
        options,
        setOptions,
        selectedOption,
        onSelect,
        onSearch,
        isLoading,
        onReset,
    };
}
