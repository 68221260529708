import $ from "jquery";

import { API_URL } from "settings";

export function ajax_wrapper(type, url, data, returnFunc) {
    if (type === "POST" || type === "PUT") {
        data = JSON.stringify(data);
    }
    return ajaxWithRetry({
        type,
        url: absoluteURL(url),
        contentType: "application/json",
        data,
    })
        .catch((xhr) => {
            return {
                error: xhr.statusText,
                code: xhr.status,
                data: xhr.responseJSON,
            };
        })
        .then(returnFunc);
}

export function isErrorResponse(data) {
    return !data || data.error || data.code;
}

// Wraps $.ajax to refresh the token and retry the original request if the
// server responds with HTTP 401 Unauthorized (token expired).
async function ajaxWithRetry(options, attempts = 3) {
    for (let i = 0; i < attempts; i++) {
        try {
            return await $.ajax({
                beforeSend,
                ...options,
            });
        } catch (error) {
            if (error.status === 401 && !options.url.endsWith("/user/token/")) {
                await sleep(1000);
                await refreshToken();
            } else {
                throw error;
            }
        }
    }
    throw new Error("Maximum number of attempts reached");
}

async function refreshToken() {
    const refresh = getRefreshToken();
    if (!refresh) {
        throw new Error("Session expired. Please login again.");
    }
    try {
        const value = await $.ajax({
            type: "POST",
            url: absoluteURL("/user/token/refresh/"),
            contentType: "application/json",
            data: JSON.stringify({ refresh }),
        });
        saveCurrentToken(value);
    } catch (error) {
        clearCurrentToken();
    }
}

async function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

function saveCurrentToken(value) {
    return isImpersonationActive() ? saveImpersonationToken(value) : save_token(value);
}

export function clearCurrentToken() {
    return isImpersonationActive() ? clearImpersonationToken() : clear_token();
}

export function save_token(value) {
    localStorage.setItem("token", value.access);
    // Refresh token is only present on initial login
    if (value.refresh) {
        localStorage.setItem("refresh_token", value.refresh);
    }
}

export function clear_token() {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
}

export function saveImpersonationToken(value) {
    localStorage.setItem("token_imp", value.access);
    // Refresh token is only present on initial login
    if (value.refresh) {
        localStorage.setItem("refresh_token_imp", value.refresh);
    }
}

export function clearImpersonationToken() {
    localStorage.removeItem("token_imp");
    localStorage.removeItem("refresh_token_imp");
}

// Return true if auth token is present.
export function isLoggedIn() {
    return !!getAuthToken();
}

export function getAuthToken() {
    return isImpersonationActive()
        ? localStorage.getItem("token_imp")
        : localStorage.getItem("token");
}

export function getRefreshToken() {
    return isImpersonationActive()
        ? localStorage.getItem("refresh_token_imp")
        : localStorage.getItem("refresh_token");
}

export function isImpersonationActive() {
    return !!localStorage.getItem("token_imp");
}

// Ajax GET request.
export function fetch(url) {
    return ajaxWithRetry({
        type: "GET",
        url: absoluteURL(url),
    });
}

// Ajax POST raw form data.
export function postData(url, data, opts) {
    return ajaxWithRetry({
        type: "POST",
        url: absoluteURL(url),
        data,
        ...opts,
    });
}

// Ajax POST JSON data.
export function post(url, data, opts) {
    opts = { contentType: "application/json", ...opts };
    return ajaxWithRetry({
        type: "POST",
        url: absoluteURL(url),
        data: JSON.stringify(data),
        ...opts,
    });
}

// Ajax request middleware to attach CSRF token and JWT.
function beforeSend(request) {
    const csrf = getCookie("csrftoken");
    request.setRequestHeader("X-CSRFToken", csrf);

    const token = getAuthToken();
    if (token) {
        request.setRequestHeader("Authorization", `Bearer ${token}`);
    }
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(";").shift();
    }
    return null;
}

// Parse code from error response.
export function parseErrorCode(xhr) {
    return xhr.responseJSON?.code;
}

// Convert rest framework error response to text.
export function parseErrorResponse(xhr) {
    if (xhr.responseJSON) {
        const { error, code, message, detail, data } = xhr.responseJSON;
        // Handle APIExceptions
        if (detail) {
            // Handle simplejwt error format
            return `Error: ${detail}`;
        } else if (message) {
            // Handle APIException format
            return `Error: ${message}`;
        } else if (error && data) {
            // Handle Django error_response with data
            return `${error}: ${JSON.stringify(data)}`;
        } else if (error) {
            // Handle Django error_response without data
            return error;
        }
        return `${xhr.statusText}: ${JSON.stringify(xhr.responseJSON)}`;
    } else if (xhr.message) {
        return xhr.message;
    } else if (xhr.status == 0) {
        // Handle server down
        return "Server unreachable";
    }
    return xhr.statusText;
}

function absoluteURL(url) {
    return url.startsWith("http") ? url : `${API_URL}${url}`;
}
