import { useCallback, useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import {
    fetch,
    formatCredits,
    formatNumber,
    parseErrorResponse,
    pluralize,
    post,
    sleep,
} from "functions";

import type {
    ParcelExport,
    ParcelExportFields,
    ParcelExportPriceBreakdown,
} from "../types";

const TIMEOUT_INTERVAL = 1000;

interface ExportListModalProps {
    dumpParcelExportFields: () => ParcelExportFields;
    onClose: () => void;
}

export default function ExportListModal({
    dumpParcelExportFields,
    onClose,
}: ExportListModalProps) {
    const [parcelExport, setParcelExport] = useState<ParcelExport>();
    const [priceResult, setPriceResult] = useState<ParcelExportPriceBreakdown>();
    const [error, setError] = useState("");

    const purchaseExport = async () => {
        // Clear price state in order for "Loading..." indicator to appear
        setPriceResult(null);

        try {
            const data = dumpParcelExportFields();
            const parcelExport = await post("/api/property/exports/purchase/", data);
            setParcelExport(parcelExport);
            // Check status in 1 second
            await sleep(TIMEOUT_INTERVAL);
            fetchExport(parcelExport.id);
        } catch (xhr) {
            console.error(xhr);
            setError(`Error exporting parcels: ${parseErrorResponse(xhr)}`);
        }
    };

    const fetchExport = async (id: string) => {
        try {
            const parcelExport = await fetch(`/api/property/exports/${id}/`);
            setParcelExport(parcelExport);
            if (parcelExport.error) {
                setError(
                    `Error exporting parcels: ${JSON.stringify(parcelExport.error)}`,
                );
            } else if (!parcelExport.file) {
                // Try again in 1 second
                await sleep(TIMEOUT_INTERVAL);
                fetchExport(id);
            }
        } catch (xhr) {
            console.error(xhr);
            setError(`Error exporting parcels: ${parseErrorResponse(xhr)}`);
        }
    };

    const calculatePrice = useCallback(async () => {
        try {
            const data = dumpParcelExportFields();
            const priceResult: ParcelExportPriceBreakdown = await post(
                `/api/property/exports/calculate_price/`,
                data,
            );
            setPriceResult(priceResult);
        } catch (xhr) {
            console.error(xhr);
            setError(
                `Error calculating parcel export price: ${parseErrorResponse(xhr)}`,
            );
        }
    }, [dumpParcelExportFields]);

    useEffect(() => {
        calculatePrice();
    }, [calculatePrice]);

    const tableItems = [];

    if (priceResult) {
        tableItems.push(
            ...[
                ["Total", formatCredits(priceResult.total_price)],
                ["Rows", formatNumber(priceResult.row_summary.total_rows)],
                [
                    "Records Total",
                    formatCredits(priceResult.parcel_export_price.total_price),
                ],
                [
                    "Records Price",
                    `${priceResult.parcel_export_price.price_per_row} ${pluralize("credit", priceResult.parcel_export_price.price_per_row)} / record`,
                ],
            ],
        );
        if (priceResult.land_scrub_price) {
            tableItems.push(
                ...[
                    [
                        "Scrubbing Total",
                        formatCredits(priceResult.land_scrub_price.total_price),
                    ],
                    [
                        "Land Locked Scrub Price",
                        `${priceResult.land_scrub_price.land_locked_price_per_row} ${pluralize("credit", priceResult.land_scrub_price.land_locked_price_per_row)} / record`,
                    ],
                    [
                        "Wetlands Scrub Price",
                        `${priceResult.land_scrub_price.wetlands_price_per_row} ${pluralize("credit", priceResult.land_scrub_price.wetlands_price_per_row)} / record`,
                    ],
                    [
                        "Wetlands Scrub Total",
                        formatCredits(priceResult.land_scrub_price.wetlands_price),
                    ],
                    [
                        "Flood Zone Scrub Price",
                        `${priceResult.land_scrub_price.flood_zone_price_per_row} ${pluralize("credit", priceResult.land_scrub_price.flood_zone_price_per_row)} / record`,
                    ],
                    [
                        "Flood Zone Scrub Total",
                        formatCredits(priceResult.land_scrub_price.flood_zone_price),
                    ],
                ],
            );
        }
        if (priceResult.skip_trace_price) {
            tableItems.push(
                ...[
                    [
                        "Skipping Total",
                        formatCredits(priceResult.skip_trace_price.total_price),
                    ],
                    [
                        "Skipping Price",
                        `${priceResult.skip_trace_price.price_per_row} ${pluralize("credit", priceResult.skip_trace_price.price_per_row)} / record`,
                    ],
                    [
                        "Skipping DNC Price",
                        `${priceResult.skip_trace_price.scrub_dnc_price_per_row} ${pluralize("credit", priceResult.skip_trace_price.scrub_dnc_price_per_row)} / record`,
                    ],
                    [
                        "Skipping DNC Total",
                        formatCredits(priceResult.skip_trace_price.scrub_dnc_price),
                    ],
                ],
            );
        }
    }

    const modalBody = (() => {
        if (error) {
            return <span className="text-danger">{error}</span>;
        } else if (parcelExport && parcelExport.file) {
            return (
                <Button
                    as="a"
                    variant="primary"
                    size="lg"
                    className="mb-0"
                    href={parcelExport.file}
                    target="_blank"
                    rel="noreferrer"
                >
                    Download
                </Button>
            );
        } else if (priceResult) {
            return (
                <>
                    <table className="table w-50 mx-auto border">
                        <tbody>
                            {tableItems?.map((row) => (
                                <tr key={row[0]}>
                                    <th className="text-start">{row[0]}:</th>
                                    <td className="text-end">{row[1]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <button className="btn btn-primary" onClick={purchaseExport}>
                        Purchase
                    </button>
                </>
            );
        }
        return (
            <>
                Loading... <Spinner animation="border" size="sm" />
            </>
        );
    })();

    return (
        <Modal show size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Export Parcels
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center">{modalBody}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
